import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Footer from "../../components/auth/Footer";
import NavBar from "../../components/auth/NavBar";
import bg from "../../assets/img/bg.svg";
import "../../assets/css/soft-ui-dashboard-tailwind.css?v=1.0.5";
import { Link } from "react-router-dom";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [agreement, setAgreement] = useState(false); // Inicialmente no seleccionado
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetAgreement = () => setAgreement(!agreement);

  const { name, email, password, confirmPassword } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (!agreement) {
      setErrors({ agreement: "Debes aceptar los términos y condiciones." });
      return;
    }

    // Validación simple
    const newErrors = {};
    if (!name) newErrors.name = "El nombre es obligatorio.";
    if (!email) newErrors.email = "El correo es obligatorio.";
    if (!password) newErrors.password = "La contraseña es obligatoria.";
    if (password !== confirmPassword)
      newErrors.confirmPassword = "Las contraseñas deben coincidir.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const fullName = name;
      const rol = "cliente";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          fullName,
          email,
          password,
          rol,
        }
      );

      if (response.status === 201) {
        // Store the user ID in local storage
        localStorage.setItem("userID", response.data.data);

        Swal.fire({
          title: "¡Registro exitoso!",
          text: "¡Bienvenido a Watti! Recibirás un correo electrónico para la activación de tu cuenta.",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#027BD3",
        }).then(() => {
          window.location.href = "/token/verify";
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { message } = error.response.data;
        const newErrors = {};
        message.forEach((msg) => {
          if (msg.toLowerCase().includes("correo")) newErrors.email = msg;
          if (msg.toLowerCase().includes("password")) newErrors.password = msg;
          if (msg.toLowerCase().includes("nombre")) newErrors.name = msg;
        });
        setErrors(newErrors);
      }
    }
  };

  return (
    <div className="m-0 font-sans antialiased font-normal bg-white text-start text-base leading-default text-slate-500">
      <NavBar />
      <main className="mt-0 transition-all duration-200 ease-soft-in-out">
        <section>
          <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="container z-10">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 mt-32 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                      <h3
                        className="relative z-10 font-poppins-bold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                        style={{ fontSize: "32px" }}
                      >
                        Únete a Watti hoy
                      </h3>
                      <p className="mb-0">
                        Ingresa tu correo electrónico y contraseña para
                        registrarte
                      </p>
                    </div>
                    <div className="flex-auto p-6">
                      <form role="form" onSubmit={onSubmit}>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Nombre
                        </label>
                        <div className="mb-4">
                          <input
                            type="text"
                            name="name"
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Nombre"
                            aria-label="Nombre"
                            value={name}
                            onChange={onChange}
                          />
                          {errors.name && (
                            <div className="text-xs text-red-500">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Correo electrónico
                        </label>
                        <div className="mb-4">
                          <input
                            type="email"
                            name="email"
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Correo electrónico"
                            aria-label="Correo electrónico"
                            value={email}
                            onChange={onChange}
                          />
                          {errors.email && (
                            <div className="text-xs text-red-500">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Contraseña
                        </label>
                        <div className="mb-4 relative">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Contraseña"
                            aria-label="Contraseña"
                            value={password}
                            onChange={onChange}
                          />
                          <span
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <IconEyeOff size={18} />
                            ) : (
                              <IconEye size={18} />
                            )}
                          </span>
                          {errors.password && (
                            <div className="text-xs text-red-500">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Confirmar Contraseña
                        </label>
                        <div className="mb-4 relative">
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="confirmPassword"
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Confirmar Contraseña"
                            aria-label="Confirmar Contraseña"
                            value={confirmPassword}
                            onChange={onChange}
                          />
                          <span
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {confirmPasswordVisible ? (
                              <IconEyeOff size={18} />
                            ) : (
                              <IconEye size={18} />
                            )}
                          </span>
                          {errors.confirmPassword && (
                            <div className="text-xs text-red-500">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </div>
                        <div className="flex items-center pl-2 mb-0.5">
                          <label className="flex items-center cursor-pointer select-none text-sm text-slate-700">
                            <input
                              id="terms"
                              className="mr-2"
                              type="checkbox"
                              checked={agreement}
                              onChange={handleSetAgreement}
                            />
                          </label>
                          <span className="ml-1 select-none text-sm text-slate-700">
                            Estoy de acuerdo con los{" "}
                            <Link
                              href="#"
                              className="font-poppins-bold text-slate-700"
                            >
                              Términos y Condiciones
                            </Link>
                          </span>
                          {errors.agreement && (
                            <div className="text-xs text-red-500">
                              {errors.agreement}
                            </div>
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="inline-block w-full px-6 py-3 mt-6 mb-0 font-poppins-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-blue-600 to-cyan-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
                          >
                            Registrarse
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center pt-0 px-sm-4 px-1">
                      <p className="mb-4 mx-auto">
                        ¿Ya tienes una cuenta?{" "}
                        <Link
                          to="/"
                          className="relative z-10 font-poppins-semibold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                        >
                          Iniciar sesión
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-full px-3 lg:flex-0 shrink-0 md:w-6/12">
                  <div className="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                    <div
                      className="absolute inset-x-0 top-0 z-0 h-full -ml-16 bg-cover skew-x-10"
                      style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Register;
