import React, { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import { Link } from "react-router-dom";
import {
  IconMail,
  IconShieldCheck,
  IconLock,
  IconArrowRightCircle,
  IconUsers,
  IconFileText,
  IconWallet,
  IconCircleCheck,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import axios from "axios";
import ClimaComponent from "../components/Clima.js";
import Plantas from "../components/perfil/Plantas.js";
import Card from "../components/Card";
function Dashboard() {
  const user = useSelector((state) => state.auth.user);
  const [plantIds, setPlantIds] = useState([]);


  const handleCopyLink = () => {
    // Aquí va la lógica para copiar el enlace
    alert("Enlace copiado al portapapeles");
  };

  const handleInviteFriends = () => {
    // Aquí va la lógica para invitar a amigos
    alert("Invitación enviada");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/planta/mis-plantas`,
          config
        );
        const ids = response.data.message ? response.data.message : [];
        setPlantIds(ids);
      } catch (error) {
        console.error(error);
      }
    };

    const interval = setInterval(fetchData, 5 * 60 * 1000);

    fetchData();
    return () => clearInterval(interval);
  }, []);

  return (
    <DashboardLayout>
      <main className="min-h-screen dark:bg-gray-900 text-gray-900 dark:text-white">
        {/* <ClimaComponent /> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Card Información de Perfil */}
          <div className="flex flex-col bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <div className="text-xl font-poppins-semibold leading-snug">
              Información de Perfil
            </div>
            <div className="flex flex-col mt-6 w-full">
              <div className="text-base">
                <div>
                  <span className="font-poppins-bold">Nombre: </span>
                  <span className="text-gray-700 dark:text-gray-300">
                    {user&&user.fullName}
                  </span>
                </div>
                <div className="mt-1">
                  <span className="font-poppins-bold">Id: </span>
                  <span className="text-gray-700 dark:text-gray-300">
                    {sessionStorage.getItem("id")}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mt-4 space-y-4">
                <div className="flex gap-4 items-center">
                  <IconMail size={24} />
                  <div className="text-xs font-poppins-semibold flex-1">
                    Correo electrónico
                  </div>
                  <div className="flex items-center text-xs text-emerald-400 bg-emerald-400/10 py-0.5 px-1 border border-emerald-400 rounded-md">
                    <span className="mt-0.5">Verificado</span>
                    <IconCircleCheck size={20} className="ml-1" />
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <IconShieldCheck size={24} />
                  <div className="text-xs font-poppins-semibold">
                    Autenticación de dos factores (2FA)
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <IconLock size={24} />
                  <div className="text-xs font-poppins-semibold">Contraseña</div>
                </div>
              </div>
              <Link
                to="/perfil"
                className="mt-6 py-2 px-4 text-sm font-poppins-medium rounded-lg text-center transition-colors text-white bg-[#00A1FF] hover:bg-[#0088ff]"
              >
                Ir al perfil
              </Link>
            </div>
          </div>

          {/* Card Proceso de Solicitud */}

          <Card status={user&&user.estadoSolicitud} paso={user&&user.pasoSolicitud} subpaso={user&&user.subPasoSolicitud}/>

          {/* <div className="space-y-4">
            <div className="flex flex-col lg:col-span-2 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
              <div className="text-xl font-poppins-semibold">Enlaces rápidos</div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 mt-4 text-center">
                <Link
                  to="/wallet"
                  className="flex flex-col justify-center items-center px-1 py-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                  <IconWallet size={24} />
                  <div className="text-xs mt-1">Wallet</div>
                </Link>
                <Link
                  to="/referidos"
                  className="flex flex-col justify-center items-center px-1 py-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                  <IconUsers size={24} />
                  <div className="text-xs mt-1">Referidos</div>
                </Link>
                <Link
                  to="/factura"
                  className="flex flex-col justify-center items-center px-1 py-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                  <IconFileText size={24} />
                  <div className="text-xs mt-1">Factura</div>
                </Link>
                <Link
                  to="/inversion"
                  className="flex flex-col justify-center items-center px-1 py-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                  <IconArrowRightCircle size={24} />
                  <div className="text-xs mt-1">Inversión</div>
                </Link>
              </div>
            </div>

            <div className="flex flex-col bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 mt-4 lg:mt-0">
              <div className="text-xl font-poppins-semibold">Enlace de referidos</div>
              <div className="flex gap-2 mt-4">
                <div className="flex flex-1 items-center px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded-lg shadow text-xs truncate">
                  https://acount/referidos/watti......
                </div>
                <div className="flex justify-center items-center px-2 py-2 bg-white dark:bg-gray-600 rounded-lg text-gray-700 dark:text-white">
                  <button
                    onClick={handleCopyLink}
                    className="text-xs font-poppins-semibold"
                  >
                    <IconArrowRightCircle size={24} />
                  </button>
                </div>
              </div>
              <div className="flex gap-4 justify-center items-center mt-4 px-4 py-2 text-sm font-poppins-semibold text-center rounded-lg transition-colors bg-[#00A1FF] hover:bg-[#0088ff]">
                <span className="flex-1 text-white">Invitar a amigos</span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="w-full  flex fle-wrap py-2">
          <div className="flex flex-col justify-center p-5 mt-5 w-full bg-white dark:bg-gray-700 rounded-xl shadow">
            <div className="flex flex-col max-w-full leading-snug w-[151px]">
              <div className="text-base font-poppins-bold text-black dark:text-white">
                Mis Plantas
              </div>
              {/* <div className="mt-1 text-xs text-gray-600 dark:text-gray-400">
                Architects design houses
              </div> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 items-center mt-5">
            {plantIds &&
              plantIds.map((plantId) => (
                <Plantas key={plantId} plantId={plantId} />
              ))}
              </div>

          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default Dashboard;
