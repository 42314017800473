import React, { useEffect } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { getSolicitudes } from "../../redux/actions/admin/solicitudes/solicitudes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconFileText, IconPencil } from "@tabler/icons-react";
import Perfil from "../../components/perfil/Profile";

function AdminSolicitudes({ getSolicitudes, solicitudes }) {
  useEffect(() => {
    getSolicitudes();
  }, []);

  return (
    <DashboardLayout>
      <div className="flex flex-col py-5 px-5 rounded-xl bg-white dark:bg-gray-900">
        {/* Perfil */}
        <Perfil perfil={false} />
        

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-7 items-start mt-6">
          {[
            { title: "Total Solicitudes", value: 12, growth: "+55%" },
            { title: "Solicitudes Aprobadas", value: 18, growth: "+55%" },
            { title: "Solicitudes Desaprobadas", value: 8, growth: "+55%" },
            { title: "Total Solicitudes del Mes", value: 4, growth: "+55%" },
          ].map((card, index) => (
            <div
              key={index}
              className="flex flex-col justify-between p-4 bg-gray-100 dark:bg-gray-800 rounded-xl border border-solid border-gray-300 dark:border-zinc-600 shadow-md w-full"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="text-base font-semibold text-gray-700 dark:text-slate-400 truncate">
                  {card.title}
                </div>
                <div className="flex justify-center items-center h-8 w-8 bg-sky-700 dark:bg-sky-500 rounded-md shadow-md"></div>
              </div>
              <div className="flex gap-2 items-end">
                <div className="text-xl font-bold text-gray-900 dark:text-white">
                  {card.value}
                </div>
                <div className="text-sm font-medium text-green-600 dark:text-green-400">
                  {card.growth}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Título de Filtros */}
        <div className="text-2xl font-semibold tracking-tighter leading-none text-gray-900 dark:text-white mt-6">
          Consulta de solicitudes
        </div>

        {/* Filtros */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-7 mt-4">
          {[
            { label: "No. Cliente", placeholder: "e.g. Luxury" },
            { label: "Desde", placeholder: "Fecha", type: "date" },
            { label: "Hasta", placeholder: "Fecha", type: "date" },
            { label: "Nombre del cliente", placeholder: "Nombre" },
            { label: "Estado de la solicitud", placeholder: "Etapa de la solicitud" },
            { label: "Estrato", placeholder: "Número de estrato", type: "number" },
            { label: "Ciudad", placeholder: "Ciudad" },
            { label: "Asesor asignado", placeholder: "Nombre asesor" },
            { label: "Tipo de Servicio", placeholder: "Nombre del servicio" },
          ].map((filter, index) => (
            <div key={index} className="flex flex-col">
              <label className="font-semibold text-gray-900 dark:text-white">
                {filter.label}
              </label>
              <input
                type={filter.type || "text"}
                placeholder={filter.placeholder}
                className="px-3 py-2.5 mt-1 w-full text-gray-900 bg-gray-100 dark:text-gray-300 dark:bg-gray-800 rounded-md border border-gray-300 dark:border-gray-700"
              />
            </div>
          ))}
        </div>

        {/* Botón Buscar */}
        <div className="flex justify-end mt-4">
          <button className="px-6 py-2 text-white bg-blue-500 dark:bg-blue-600 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700">
            Buscar
          </button>
        </div>

        {/* Tabla de Solicitudes */}
        <div className="mt-8 p-4 bg-gray-100 dark:bg-gray-800 rounded-xl border border-gray-300 dark:border-white dark:border-opacity-10 shadow-lg">
          <div className="flex justify-between items-center text-xs text-gray-900 dark:text-white uppercase mb-4">
            <div>Solicitudes</div>
            <input
              type="text"
              placeholder="Buscar"
              className="px-3 py-2 w-1/4 text-gray-900 bg-gray-100 dark:text-gray-300 dark:bg-gray-800 rounded-md border border-gray-300 dark:border-gray-700"
            />
          </div>

          <div className="w-full overflow-x-scroll">
            <table className="min-w-full bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl">
              <thead>
                <tr className="border-b border-gray-300 dark:border-gray-600">
                  {[
                    "Nombre Solicitante",
                    "Fecha de Solicitud",
                    "Tipo de servicio",
                    "Estrato",
                    "Ciudad",
                    "Solución",
                    "Asesor",
                    "Estado",
                  ].map((header) => (
                    <th
                      key={header}
                      className="py-2 px-4 text-nowrap text-left text-xs uppercase"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {solicitudes && solicitudes.length > 0 ? (
                  solicitudes.map((solicitud) => (
                    <tr
                      key={solicitud.id}
                      className="border-b border-gray-300 dark:border-gray-600"
                    >
                      <td className="py-2 px-4">
                        <Link
                          to={`/admin/solicitud/detalle/${solicitud.id}`}
                          className="text-blue-500 dark:text-blue-400 underline flex items-center"
                        >
                          {solicitud.nombre_solicitante}
                        </Link>
                        
                      </td>
                      <td className="py-2 px-4">
                        {new Date(solicitud.created_at).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4">
                        {
                          (() => {
                            switch (solicitud.tipo) {
                              case 1: {
                                return "Residencial";
                              }
                              case 2: {
                                return "Comercial";
                              }
                              case 3: {
                                return "Industrial";
                              }
                              default: {
                                return "No definido";
                              }
                            }
                          })()
                        }
                      </td>
                      <td className="py-2 px-4">{solicitud.estrato}</td>
                      <td className="py-2 px-4">{solicitud.ciudad}</td>
                      <td className="py-2 px-4">{solicitud.solucion}</td>
                      <td className="py-2 px-4">{solicitud.asesor}</td>
                      <td
                        className={`py-2 px-4 ${
                          solicitud.estado === "SOLICITUD"
                            ? "text-yellow-500"
                            : solicitud.estado === "FIRMA DE CONTRATO"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {solicitud.estado}
                      </td>
                      {/* <td className="py-2 px-4">
                        <Link
                          to={`/admin/solicitud/detalle/${solicitud.id}`}
                          className="text-blue-500 dark:text-blue-400 underline flex items-center"
                        >
                          <IconPencil className="mr-1" /> Editar
                        </Link>
                        <a
                          href={`/path/to/recibo/${solicitud.recibo}`}
                          className="text-blue-500 dark:text-blue-400 underline flex items-center"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconFileText className="mr-1" /> Ver Recibo
                        </a>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center py-4 text-gray-500 dark:text-gray-700">
                      No hay solicitudes disponibles
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  solicitudes: state.solicitudes.solicitudes,
});

export default connect(mapStateToProps, { getSolicitudes })(AdminSolicitudes);
