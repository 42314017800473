import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  IconHome,
  IconChevronDown,
  IconFileInvoice,
  IconCreditCard,
  IconUser,
  IconUsers,
  IconSettings,
  IconHelpOctagon,
} from "@tabler/icons-react";

function Sidebar({ selectedItem, handleSidebarClick, darkTheme }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({});
  const user = useSelector((state) => state.auth.user);

  const isAdmin = (user) => {
    return user?.rol === 'admin';
  };

  const clientNavigation = [
    {
      name: "Inicio",
      href: "/inicio",
      icon: IconHome,
    },
    {
      name: "Facturas",
      href: "/facturas",
      icon: IconFileInvoice,
    },
    // {
    //   name: "Wallet Watti",
    //   href: "/wallet-watti",
    //   icon: IconCreditCard,
    // },
    {
      name: "Perfil",
      href: "/perfil",
      icon: IconUser,
    },

    // {
    //   name:"Referidos",
    //   href: "/referidos",
    //   icon: IconUsers,
    // },
    // {
    //   name:"Ajustes",
    //   href: "/ajustes",
    //   icon: IconSettings,
    // },

    {
      name: "Soporte",
      href: "/soporte",
      icon: IconHelpOctagon,
    },
  ];

  const adminNavigation = [
    {
      name: "Inicio Admin",
      href: "/admin/inicio",
      icon: IconHome,
    },
    {
      name: "Solicitudes",
      href: "/admin/solicitudes",
      icon: IconFileInvoice,
    },
    {
      name: "User Management",
      href: "/admin/users",
      icon: IconUsers,
    },
    {
      name: "Settings",
      href: "/admin/settings",
      icon: IconSettings,
    },
    {
      name: "Perfil",
      href: "/admin/perfil",
      icon: IconUser,
    }
    // Agrega más vistas de administrador aquí
  ];

  useEffect(() => {
    const pathname = location.pathname;
    const newIsOpen = {};

    const currentNavigation = isAdmin(user) ? adminNavigation : clientNavigation;

    currentNavigation.forEach((item) => {
      if (item.children) {
        const hasSelectedChild = item.children.some(
          (child) =>
            child.href === pathname ||
            (child.children &&
              child.children.some((grandchild) => grandchild.href === pathname))
        );
        if (hasSelectedChild) {
          newIsOpen[item.name] = true;
        }
        item.children.forEach((child) => {
          if (child.children) {
            const hasSelectedGrandChild = child.children.some(
              (grandchild) => grandchild.href === pathname
            );
            if (hasSelectedGrandChild) {
              newIsOpen[child.name] = true;
            }
          }
        });
      }
    });

    setIsOpen(newIsOpen);
  }, [location, user]);

  const handleDropdownClick = (name) => {
    setIsOpen((prevIsOpen) => ({ ...prevIsOpen, [name]: !prevIsOpen[name] }));
  };

  const renderNavItem = (item, level = 0) => {
    if (item.children) {
      return (
        <li key={item.name} className="mt-0.5 w-full">
          <div
            className={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors cursor-pointer ${
              selectedItem === item.name
                ? "shadow-soft-xl bg-gradient-to-tl from-gray-500 to-gray-700 font-poppins-semibold text-white"
                : darkTheme
                ? "text-gray-300"
                : "text-gray-700"
            }`}
            onClick={() => handleDropdownClick(item.name)}
          >
            <div
              className={`mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
                selectedItem === item.name
                  ? "bg-gradient-to-tl from-gray-500 to-gray-700 shadow-soft-2xl"
                  : darkTheme
                  ? "bg-gray-700"
                  : "bg-gray-200"
              } transition-colors duration-200`}
            >
              <item.icon
                className={`text-${
                  selectedItem === item.name ? "blue-500" : darkTheme ? "white" : "black"
                }`}
              />
            </div>
            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
              {item.name}
            </span>
            <IconChevronDown
              className={`h-5 w-5 text-gray-600 transition-transform ${
                isOpen[item.name] ? "rotate-180 transform" : ""
              }`}
            />
          </div>
          {isOpen[item.name] && (
            <ul className="ml-4 mt-2 flex flex-col gap-2">
              {item.children.map((child) => renderNavItem(child, level + 1))}
            </ul>
          )}
        </li>
      );
    } else {
      return (
<li key={item.name} className="mt-0.5 w-full">
  <NavLink
    to={item.href}
    className={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors ${
      selectedItem === item.name
        ? "shadow-soft-xl bg-gradient-to-tl from-[#00A1FF] to-blue-700 font-poppins-semibold text-white"
        : darkTheme
        ? "text-gray-300"
        : "text-gray-700"
    }`}
    onClick={() => handleSidebarClick(item.name)}
  >
    <div
      className={`mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
        selectedItem === item.name
          ? "bg-gradient-to-tl from-[#00A1FF] to-blue-700 shadow-soft-2xl"
          : darkTheme
          ? "bg-[#00A1FF]"
          : "bg-gray-200"
      } transition-colors duration-200`}
    >
      <item.icon
        className={`text-${
          selectedItem === item.name ? "white" : darkTheme ? "white" : "black"
        }`}
      />
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
      {item.name}
    </span>
  </NavLink>
</li>

      );
    }
  };

  const currentNavigation = isAdmin(user) ? adminNavigation : clientNavigation;

  return (
    <ul className="flex flex-col pl-0 mb-0">
      {currentNavigation.map((item) => renderNavItem(item))}
    </ul>
  );
}

export default Sidebar;
