import { Link } from "react-router-dom";

function Plantas({plantId}) {
  return (


      <div className="flex flex-col self-stretch my-auto w-full rounded-xl">

        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e9c2932b447ff115661519ca21bbbf7ce54977147c0fb79d76bb8219707334b3"
          className="object-contain max-w-full rounded-2xl"
        />
        <div className="flex flex-col mt-3 w-full">
          <div className="text-base leading-snug text-black dark:text-white">
            Modern
          </div>
          <div className="mt-1 text-xs text-gray-600 dark:text-gray-400">
            Project #1
          </div>
        </div>
        <div className="my-3 text-xs leading-3 text-gray-600 dark:text-gray-400">
          As Uber works through a huge amount of internal management turmoil.
        </div>
        <Link 
          to={`/monitoreo/${plantId}`} 
          className="w-full text-sm font-poppins-medium leading-none text-white bg-[#00A1FF] rounded-lg py-2 text-center dark:bg-[#00A1FF]"
        >
          Ver Planta
        </Link>
      </div>
    
  );
}

export default Plantas;
