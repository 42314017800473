import {
  SOLICITUDES_SUCCESS,
  SOLICITUDES_FAIL,
  SOLICITUD_SUCCESS,
  SOLICITUD_FAIL
} from "../../actions/admin/solicitudes/types"

const initialState = {
  solicitudes: [],
  solicitud: null,
  loading: true
}

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SOLICITUDES_SUCCESS:
      return {
        ...state,
        solicitudes: payload,
        loading: false
      }
    case SOLICITUDES_FAIL:
      return {
        ...state,
        solicitudes: [],
        loading: false
      }
    case SOLICITUD_SUCCESS:
      return {
        ...state,
        solicitud: payload,
        loading: false
      }
    case SOLICITUD_FAIL:
      return {
        ...state,
        solicitud: null,
        loading: false
      }
    default:
      return state;
  }
}