import { IconBolt } from '@tabler/icons-react';

function Reglas() {
  return (
    <div className="flex flex-col bg-white dark:bg-[#333844] p-6 rounded-xl shadow-md mt-4">
      <div className="flex flex-col max-md:max-w-full">
        <div className="flex gap-1.5 items-start self-start p-1 whitespace-nowrap">
          <div className="flex justify-center items-center w-10 h-10 rounded-full bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-lg">
            <IconBolt size={24} strokeWidth={2} className="text-sky-500" />
          </div>
          <div className="text-2xl font-poppins-semibold tracking-tight leading-none text-gray-800 dark:text-gray-100 ml-2">
            Reglas
          </div>
        </div>
        <div className="mt-1.5 text-sm leading-5 text-gray-600 dark:text-gray-400 max-md:max-w-full">
          ¡Bienvenido al Plan de Referidos SolarFlex! Aquí te explicamos de manera sencilla cómo puedes invitar a tus amigos y ganar increíbles premios y beneficios acumulando energía.
        </div>
      </div>

      <div className="flex flex-col mt-7 text-sm max-md:max-w-full">
        <div className="font-poppins-semibold text-gray-800 dark:text-gray-100">Descripción:</div>
        <div className="mt-2 leading-5 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
          <ul className="list-disc list-inside">
            <li className="mb-2">
              <span className="font-poppins-bold text-gray-800 dark:text-gray-100">Invita a tus Amigos:</span> Comparte tu enlace de referido con tus amigos y familiares. Cada vez que alguien se registre a través de tu enlace, quedará vinculado como tu referido.
            </li>
            <li className="mb-2">
              <span className="font-poppins-bold text-gray-800 dark:text-gray-100">Solicita el Servicio SolarFlex:</span> Para que puedas empezar a acumular energía, tus referidos deberán solicitar e instalar el servicio SolarFlex. Este servicio permite a los usuarios generar energía limpia y renovable desde el primer día.
            </li>
            <li className="mb-2">
              <span className="font-poppins-bold text-gray-800 dark:text-gray-100">Acumula Energía Generada con SolarFlex:</span> Una vez que el sistema SolarFlex de tus referidos esté en funcionamiento y empiece a generar energía, esa energía también se reflejará en tu cuenta.
            </li>
            <li className="mb-2">
              <span className="font-poppins-bold text-gray-800 dark:text-gray-100">Redime Baterías Digitales de Energía SolarFlex por Premios y Recompensas:</span> Cuando tus Baterías Digitales alcancen ciertos niveles, podrás redimir la energía acumulada por premios exclusivos y beneficios especiales. ¡Entre más energía acumules, mejores serán las recompensas!
            </li>
          </ul>
          <br />
          <span className="font-poppins-bold text-gray-800 dark:text-gray-100">Recuerda:</span> El plan de referidos SolarFlex de Watti está diseñado para que todos ganen. Tus referidos se benefician de un servicio de energía renovable de calidad, y tú acumulas energía que puedes canjear por recompensas. ¡Empieza hoy y transforma la energía en beneficios!
        </div>
      </div>

      <div className="flex flex-wrap gap-10 justify-center items-start px-2 py-3.5 mt-7">
      <div class="flex flex-wrap gap-10 justify-center items-start px-2 py-3.5">
  <div class="flex flex-col rounded-none min-w-[240px] w-[287px]">
    <img
      loading="lazy"
      srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/37c443878c6456d239d4d7ad627c783af3b89e4417e95855074b3f4d4f22d812?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
      class="object-contain z-10 aspect-[0.71] shadow-[0px_3px_43px_rgba(0,161,255,0.64)] w-[60px]"
    />
    <div
      class="flex flex-col pt-2 pb-7 w-full rounded-2xl border-t border-white shadow-[4px_4px_5px_rgba(0,0,0,0.11)]"
    >
      <div
        class="flex gap-1.5 self-end max-w-full text-center w-[214px] max-md:mr-2.5"
      >
        <div class="grow text-lg font-poppins-bold text-sky-500">Watti Explorador</div>
        <div
          class="self-start text-sm font-poppins-medium text-white rounded-lg border-sky-500 border-solid shadow-sm bg-[linear-gradient(90deg,rgba(40,44,50,0.55_55.77%,rgba(79,88,104,0.55)_100%))] border-[0.429px] min-h-[26px]"
        >
          25 %
        </div>
      </div>
      <div class="flex flex-col px-2.5 w-full">
        <div class="gap-2 self-start p-2 text-xs font-poppins-medium text-slate-400">
          <span class="font-poppins-extrabold text-slate-400">Beneficios:</span>
          <span class="font-poppins-light text-slate-400">
            Acceso a todos los servicios sin descuentos.
          </span>
          <br />
          <br />
          <span class="font-poppins-extrabold text-slate-400">
            Acciones para llenar la batería NIVEL 1:
          </span>
        </div>
        <div class="flex gap-1.5 max-md:ml-2.5">
          <div class="flex flex-col">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/45c64c34a201ad8a48b4c175066de8389430fbb8f4b318938c2ca998309a701f?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
              class="object-contain w-3.5 aspect-[0.93]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e12f2efa5909131bdc2ba0efcae140fe4bcc0e934de13734bef1960ca43da105?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
              class="object-contain mt-1.5 w-3.5 aspect-square"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b02791bad1b7e747914d18fd536cc7ee8470cbe013f886ce24975fc8ebe74b38?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
              class="object-contain mt-2 w-3.5 aspect-[0.93]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/606d50b0372d38d6f13482ace081f97d2257e5ab61e8dca78f457bfccfaad254?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
              class="object-contain mt-1.5 w-3.5 aspect-square"
            />
          </div>
          <div class="my-auto text-xs font-poppins-medium text-slate-400">
            <ul>
              <li>
                <span class="text-xs font-poppins-extrabold text-slate-400">
                  25% Energía:
                </span>
                <span class="text-xs font-poppins-light text-slate-400">
                  Invitar a un amigo referido a Watti.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="text-xs font-poppins-extrabold text-slate-400">
                  25% Energía:
                </span>
                <span class="text-xs font-poppins-light text-slate-400">
                  El referido debe realizar una inversión minima de $200.000 COP
                  en Watti Smart World.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="text-xs font-poppins-extrabold text-slate-400">
                  25% Energía:
                </span>
                <span class="text-xs font-poppins-light text-slate-400">
                  Completar el proceso de KYC.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span class="text-xs font-poppins-extrabold text-slate-400">
                  25% Energía:
                </span>
                <span class="text-xs font-poppins-light text-slate-400">
                  El usuario debe haber realizado una inversión mínima de
                  $200.000 COP en &quot;Watti Smart World&quot;.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col rounded-none min-w-[240px] w-[287px]">
    <img
      loading="lazy"
      srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a8bf7b05a71a0d9f6ce844fc6c684b7b90149095196d5d17b451a5a5d3f643e?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
      class="object-contain z-10 aspect-[0.71] shadow-[0px_3px_43px_rgba(0,161,255,0.64)] w-[60px]"
    />
    <div
      class="flex flex-col px-2.5 pt-2 pb-7 w-full rounded-2xl border-t border-white shadow-[4px_4px_5px_rgba(0,0,0,0.11)]"
    >
      <div
        class="flex z-10 gap-1.5 self-end max-w-full text-center w-[214px] max-md:mr-0.5"
      >
        <div class="grow text-lg font-poppins-bold text-sky-500">Watti Pionero</div>
        <div
          class="self-start text-sm font-poppins-medium text-white rounded-lg border-sky-500 border-solid shadow-sm bg-[linear-gradient(90deg,rgba(40,44,50,0.55_55.77%,rgba(79,88,104,0.55)_100%))] border-[0.429px] min-h-[26px]"
        >
          0 %
        </div>
      </div>
      <div
        class="gap-2 self-stretch p-2 text-xs font-poppins-medium text-slate-400 w-[254px]"
      >
        <span class="text-xs font-poppins-extrabold text-slate-400">Beneficios:</span>
        <span class="text-xs font-poppins-light text-slate-400">3%</span>
        <span class="text-xs font-poppins-light text-slate-400">
          de descuento en el servicio de renting solar o Redimir Bono $.
        </span>
        <br />
        <br />
        <span class="text-xs font-poppins-extrabold text-slate-400">
          Acciones para llenar la batería NIVEL 2:
        </span>
      </div>
      <div class="flex gap-1.5 max-md:mr-2 max-md:ml-2.5">
        <div class="flex flex-col">
          <img
            loading="lazy"
            srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/63d6f4a90ae3cf0978859c383b6b2749a145e64ed9e80469a61157e12286a0ba?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain w-3.5 aspect-[0.41]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0a90be6c9ee6a83ce84f696cdb8005e63cec497da75faad125f5cd2372c5c51f?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain mt-2 w-3.5 aspect-[0.93]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccffc2c36231d78063629180b548c627d807c7ac97645da709aeef5e7304d87b?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain mt-1.5 w-3.5 aspect-square"
          />
        </div>
        <div class="my-auto text-xs font-poppins-medium text-slate-400">
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                Invitar un total de 2 referidos a Watti.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">Los</span>
              <span class="text-xs font-poppins-light text-slate-400">
                referidos deben realizar una inversión minima de $200.000 COP
                c/u en Watti Smart World.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                Completar 2 encuestas.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                El usuario debe haber realizado 2 inversiones mínimas de
                $200.000 COP en diferentes proyectos en &quot;Watti Smart
                World&quot;.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col rounded-none min-w-[240px] w-[286px]">
    <img
      loading="lazy"
      srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/09857d37eae54f179148b5c4c36bf6390f9c0bc9e0749d4a970ec4463c4d84ea?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
      class="object-contain z-10 aspect-[0.71] shadow-[0px_3px_43px_rgba(0,161,255,0.64)] w-[60px]"
    />
    <div
      class="flex flex-col px-4 pt-2 pb-7 w-full rounded-2xl border-t border-white shadow-[4px_4px_5px_rgba(0,0,0,0.11)]"
    >
      <div class="flex gap-1.5 self-end max-w-full text-center w-[215px]">
        <div class="grow text-lg font-poppins-bold text-sky-500">Watti Innovador</div>
        <div
          class="self-start text-sm font-poppins-medium text-white rounded-lg border-sky-500 border-solid shadow-sm bg-[linear-gradient(90deg,rgba(40,44,50,0.55_55.77%,rgba(79,88,104,0.55)_100%))] border-[0.429px] min-h-[26px]"
        >
          0 %
        </div>
      </div>
      <div class="text-xs font-poppins-medium text-slate-400 max-md:mr-1.5">
        <span class="text-xs font-poppins-extrabold text-slate-400">Beneficios:</span>
        <span class="text-xs font-poppins-light text-slate-400">7%</span>
        <span class="text-xs font-poppins-light text-slate-400">
          de descuento en el servicio de renting solar o Redimir Bono $.
        </span>
        <br />
        <br />
        <span class="text-xs font-poppins-extrabold text-slate-400">
          Acciones para llenar la batería NIVEL 3:
        </span>
      </div>
      <div class="flex gap-1.5 mt-2.5 max-md:mr-1.5 max-md:ml-0.5">
        <div class="flex flex-col">
          <img
            loading="lazy"
            srcset="https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ccfe743edcf5143be4c7bec002b5c69039e1b22993116fcd32a749bc031bdd6?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain w-3.5 aspect-[0.41]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bfa7d43018d5a5dfae061ed23c65b353ac5a13946bf0df3942d2caff2a48ab5f?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain mt-2 w-3.5 aspect-[0.93]"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2118bc9a9e8e010d7a32532cd0779d61b4a5156e1eb5ce99ffbc7c856ee0d8bf?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
            class="object-contain mt-1.5 w-3.5 aspect-square"
          />
        </div>
        <div class="my-auto text-xs font-poppins-medium text-slate-400">
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                Invitar un total de 5 referidos a Watti.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">Los</span>
              <span class="text-xs font-poppins-light text-slate-400">
                referidos deben realizar una inversión minima de $200.000 COP
                c/u en Watti Smart World.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                Completar 4 encuestas.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="text-xs font-poppins-extrabold text-slate-400">
                25% Energía:
              </span>
              <span class="text-xs font-poppins-light text-slate-400">
                El usuario debe haber realizado 4 inversiones mínimas de
                $200.000 COP en diferentes proyectos en &quot;Watti Smart
                World&quot;.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
  );
}

export default Reglas;
