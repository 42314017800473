import React, { useState, useCallback } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import axios from "axios";
import Swal from "sweetalert2";
import MapboxExample from "../../components/mapa/MapBoxForm";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const fetchAddress = async (lng, lat) => {
  const response = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=pk.eyJ1IjoianVhbnRpY2siLCJhIjoiY2x5aGVvOTU2MDN1MjJscTI0aWI3aDg3YyJ9.i8ma720LaEpOnmFxyCIk9A`
  );
  const address = response.data.features[0]?.place_name || "N/A";
  return address;
};

function SolicitarRenting() {
  const navigate = useNavigate();
  const [applicantName, setApplicantName] = useState("");
  const [serviceType, setServiceType] = useState("Residencial");
  const [phone, setPhone] = useState("");
  const [ownershipStatus, setOwnershipStatus] = useState("Dueño");
  const [receiptFile, setReceiptFile] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState("");
  const [timeInhabited, setTimeInhabited] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [nit, setNit] = useState("");
  const [coordinates, setCoordinates] = useState({
    lng: -72.5078,
    lat: 7.8891,
  });
  const [typeofproperty, setTypeOfProperty] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const handleServiceTypeChange = (type) => {
    setServiceType(type);
  };

  const handleOwnershipStatusChange = (status) => {
    setOwnershipStatus(status);
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append(
      "tipo",
      serviceType === "Residencial" ? 1 : serviceType === "Comercial" ? 2 : 3
    );
    formData.append(
      "is_propietario",
      ownershipStatus === "Dueño" ? "dueno" : ""
    );
    const formattedPhone = phone.startsWith("57") ? phone.slice(2) : phone;
    formData.append("telefono", formattedPhone);
    formData.append("recibo", receiptFile);
    formData.append("longitud", coordinates.lng);
    formData.append("latitud", coordinates.lat);
    formData.append("nombre_solicitante", applicantName);
    if (ownershipStatus === "Arrendatario") {
      formData.append("tiempo_contrato", leaseDuration);
      formData.append("tiempo_habitado", timeInhabited);
    }
    formData.append("tipo_inmueble", typeofproperty);
    if (serviceType === "Comercial" || serviceType === "Industrial") {
      formData.append("razon_social", businessName);
      formData.append("nit", nit);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/solicitud`,
        formData,
        config
      );

      if (response.status === 201) {
        Swal.fire({
          title: "Solicitud Enviada",
          text: "Tu solicitud ha sido enviada con éxito. Nos pondremos en contacto contigo para confirmar la visita técnica.",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        setIsSubmitting(false);
        navigate("/dashboard");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Hubo un problema al enviar tu solicitud. Por favor, inténtalo de nuevo más tarde.",
        icon: "error",
        confirmButtonColor: "#d33",
      });
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      phone,
      receiptFile,
      ownershipStatus,
      leaseDuration,
      timeInhabited,
      businessName,
      serviceType,
      applicantName,
      coordinates,
      typeofproperty
    );
    // if (!phone || !receiptFile || (ownershipStatus === "Arrendatario" && !leaseDuration) || !timeInhabited || (serviceType !== "Residencial" && !businessName)) {
    //   Swal.fire({
    //     title: 'Error',
    //     text: 'Por favor, completa todos los campos requeridos antes de continuar.',
    //     icon: 'error',
    //     confirmButtonColor: '#d33',
    //   });
    //   return;
    // }

    const address = await fetchAddress(coordinates.lng, coordinates.lat);

    Swal.fire({
      title: "Confirmar datos",
      html: `
        ${`Verifica que los datos sean correctos antes de enviar la solicitud:`}<br/>
        ${
          serviceType
            ? `<strong>Tipo de Servicio:</strong> ${serviceType}<br/>`
            : ""
        }
        ${
          applicantName
            ? `<strong>Nombre del Solicitante:</strong> ${applicantName}<br/>`
            : ""
        }
        ${phone ? `<strong>Teléfono:</strong> ${phone}<br/>` : ""}
        ${
          ownershipStatus
            ? `<strong>Estado del inmueble:</strong> ${ownershipStatus}<br/>`
            : ""
        }
        ${
          ownershipStatus === "Arrendatario" && leaseDuration
            ? `<strong>Duración del arrendamiento:</strong> ${leaseDuration}<br/>`
            : ""
        }
        ${
          serviceType !== "Residencial" && businessName
            ? `<strong>Razón Social:</strong> ${businessName}<br/>`
            : ""
        }
        ${
          timeInhabited
            ? `<strong>Tiempo Habitual:</strong> ${timeInhabited}<br/>`
            : ""
        }
        ${address ? `<strong>Ubicación:</strong> ${address}<br/>` : ""}
        ${
          typeofproperty
            ? `<strong>Tipo de Inmueble:</strong> ${typeofproperty}<br/>`
            : ""
        }
        ${`Si los datos son correctos, presiona "Sí, confirmar" para enviar la solicitud.`}
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true);
        submitForm();
      }
    });
  };

  const handleUpdate = useCallback((lng, lat) => {
    setCoordinates({ lng, lat });
  }, []);

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center px-5 dark:bg-gray-900 dark:text-gray-100">
        <div className="text-3xl font-poppins-bold text-center text-zinc-700 dark:text-gray-100 text-opacity-60 max-w-full">
          Formulario de Solicitud de Servicio SolarFlex
        </div>
        <div className="mt-3 text-xl text-center text-zinc-700 dark:text-gray-100 text-opacity-60 max-w-full">
          Completa este formulario para iniciar el proceso de solicitud de
          renting solar.
        </div>
        <form
          className="self-stretch mt-9 w-full flex justify-center mb-20"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col w-full max-w-lg items-center">
            <div className="flex flex-col grow mt-10 w-full">
              <div className="flex flex-col px-6 pt-6 pb-10 mx-5 mt-9 rounded-3xl border border-white border-solid shadow-lg bg-slate-100 dark:bg-gray-800 dark:border-gray-700 w-full">
                <div className="text-base font-poppins-medium tracking-tighter text-center text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  ¿Qué tipo de servicio solicitas?
                </div>
                <div className="flex gap-2 justify-between p-2 mt-5 text-base font-poppins-medium text-center whitespace-nowrap rounded-2xl bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 flex-wrap">
                  <div
                    className={`flex flex-col justify-center px-4 py-2 rounded-xl border border-solid shadow-lg ${
                      serviceType === "Residencial"
                        ? "text-white bg-sky-500 border-neutral-600"
                        : "text-zinc-700 dark:text-gray-100 bg-zinc-200 dark:bg-gray-700 border-transparent"
                    }`}
                    onClick={() => handleServiceTypeChange("Residencial")}
                  >
                    Residencial
                  </div>
                  <div
                    className={`flex flex-col justify-center px-4 py-2 rounded-xl border border-solid shadow-lg ${
                      serviceType === "Comercial"
                        ? "text-white bg-sky-500 border-neutral-600"
                        : "text-zinc-700 dark:text-gray-100 bg-zinc-200 dark:bg-gray-700 border-transparent"
                    }`}
                    onClick={() => handleServiceTypeChange("Comercial")}
                  >
                    Comercial
                  </div>
                  <div
                    className={`flex flex-col justify-center px-4 py-2 rounded-xl border border-solid shadow-lg ${
                      serviceType === "Industrial"
                        ? "text-white bg-sky-500 border-neutral-600"
                        : "text-zinc-700 dark:text-gray-100 bg-zinc-200 dark:bg-gray-700 border-transparent"
                    }`}
                    onClick={() => handleServiceTypeChange("Industrial")}
                  >
                    Industrial
                  </div>
                </div>
                {(serviceType === "Comercial" ||
                  serviceType === "Industrial") && (
                  <>
                    <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                      Razón Social:
                      <input
                        type="text"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                        placeholder="Ingresa la razón social"
                        required
                      />
                    </div>
                    <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                      NIT:
                      <input
                        type="text"
                        value={nit}
                        onChange={(e) => setNit(e.target.value)}
                        className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                        placeholder="Ingresa el NIT"
                        required
                      />
                    </div>
                  </>
                )}
                <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  Nombre del Solicitante:
                  <input
                    type="text"
                    value={applicantName}
                    onChange={(e) => setApplicantName(e.target.value)}
                    className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                    placeholder="Ingresa tu nombre"
                    required
                  />
                </div>
                <div className="self-start mt-6 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  Teléfono:
                </div>
                <div className="flex items-center mt-3.5 w-full">
                  <PhoneInput
                    country={"co"}
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                    }}
                    onlyCountries={["co"]}
                    masks={{ co: "(...) ...-...." }}
                    placeholder="Ingresa tu teléfono"
                    inputStyle={{
                      width: "100%",
                      height: "3rem",
                      borderRadius: "1rem",
                      backgroundColor: "#E5E7EB", // bg-zinc-200
                      color: "#374151", // text-zinc-700
                      border: "none",
                      paddingLeft: "3.5rem", // Para acomodar la bandera y el código del país
                    }}
                    buttonStyle={{
                      borderRadius: "1rem",
                      backgroundColor: "#E5E7EB", // bg-zinc-200
                      border: "none",
                    }}
                  />
                </div>
                <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  ¿Eres dueño del inmueble o arrendatario?
                </div>
                <div className="flex gap-2 justify-between p-2 mt-5 text-center whitespace-nowrap rounded-2xl bg-zinc-200 dark:bg-gray-700 flex-wrap">
                  <div
                    className={`flex flex-col justify-center px-4 py-2 text-base rounded-xl border border-solid shadow-lg ${
                      ownershipStatus === "Dueño"
                        ? "text-white bg-sky-500 border-neutral-600"
                        : "text-zinc-700 dark:text-gray-100 bg-zinc-200 dark:bg-gray-700 border-transparent"
                    }`}
                    onClick={() => handleOwnershipStatusChange("Dueño")}
                  >
                    Dueño
                  </div>
                  <div
                    className={`flex flex-col justify-center px-4 py-2 text-base font-poppins-medium rounded-xl shadow-lg ${
                      ownershipStatus === "Arrendatario"
                        ? "text-white bg-sky-500 border-neutral-600"
                        : "text-zinc-700 dark:text-gray-100 bg-zinc-200 dark:bg-gray-700 border-transparent"
                    }`}
                    onClick={() => handleOwnershipStatusChange("Arrendatario")}
                  >
                    Arrendatario
                  </div>
                </div>
                {ownershipStatus === "Arrendatario" && (
                  <>
                    <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                      Tiempo de contrato:
                      <input
                        type="text"
                        value={leaseDuration}
                        onChange={(e) => setLeaseDuration(e.target.value)}
                        className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                        placeholder="Ingresa el tiempo de contrato"
                        required
                      />
                    </div>
                    <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                      Tiempo Habitado:
                      <input
                        type="number"
                        value={timeInhabited}
                        onChange={(e) => setTimeInhabited(e.target.value)}
                        className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                        placeholder="¿Cuánto tiempo llevas habitando el sitio?"
                        min={0}
                        max={100}
                      />
                    </div>
                  </>
                )}
                <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  Tipo de Inmueble:
                  <select
                    value={typeofproperty}
                    onChange={(e) => setTypeOfProperty(e.target.value)}
                    className="flex justify-center items-start py-2 mt-3.5 text-base font-poppins-medium bg-zinc-200 dark:bg-gray-700 text-zinc-700 dark:text-gray-100 text-opacity-60 pr-5 w-full shadow-lg px-2.5 rounded-xl"
                    required
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Bodega">Bodega</option>
                    <option value="Local">Local</option>
                    <option value="Casa">Casa</option>
                    <option value="Apartamento">Apartamento</option>
                    <option value="Lote / Terreno">Lote / Terreno</option>
                  </select>
                </div>
                <div className="mt-5 text-base font-poppins-medium tracking-tighter text-zinc-700 dark:text-gray-100 text-opacity-60 w-full">
                  Adjunta una copia del recibo de luz:
                </div>
                <div className="flex justify-center items-center p-2 mt-3 rounded-2xl bg-zinc-200 dark:bg-gray-700 w-full">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, setReceiptFile)}
                    className="flex justify-center items-center px-5 py-3 w-full rounded-md"
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={toggleMap}
                  className="bg-gradient-to-r from-green-500 to-green-700 text-white text-base font-poppins-medium tracking-tighter text-center rounded-2xl shadow-lg py-3 mt-5 w-full"
                >
                  {showMap ? "Ocultar Mapa" : "Seleccionar Ubicación"}
                </button>
                {showMap && <MapboxExample onUpdate={handleUpdate} />}
                <button
                  type="submit"
                  className="bg-gradient-to-r from-sky-500 to-blue-500 text-white text-base font-poppins-medium tracking-tighter text-center rounded-2xl shadow-lg py-3 mt-5 w-full"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default SolicitarRenting;
