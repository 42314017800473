import { IconUser } from '@tabler/icons-react';
import React from 'react';

const referidos = [
  {
    nombre: 'Alice Vinget',
    energia: '8.232 kWh',
    email: 'alice....@gmail.com',
    fecha: '14/08/2024',
    estatus: 'ACTIVO',
    img: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9ddca117201001d423e4d6e0be8930c003a3ddf0651aa6ecdff26bedfb70291f?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a',
  },
  {
    nombre: 'John Alura',
    energia: '12.821 kWh',
    email: 'john....@gmail.com',
    fecha: '14/08/2024',
    estatus: 'ACTIVO',
    img: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f1e490d122ed9f9463d11b7567313735a3c0712d9992b1694f0d7ad76166febe?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a',
  },
  {
    nombre: 'Andrew Sian',
    energia: '2.421 kWh',
    email: 'andre....@gmail.com',
    fecha: '14/08/2024',
    estatus: 'ACTIVO',
    img: 'https://cdn.builder.io/api/v1/image/assets/TEMP/76fc1f06732f33db661caf1aa0eb245f63d530bdec926da5c7c65351976baa71?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a',
  },
  {
    nombre: 'Luca Willaim',
    energia: '5.921 kWh',
    email: 'luc....@gmail.com',
    fecha: '14/08/2024',
    estatus: 'ACTIVO',
    img: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d9dccc80e100b50cdc43acc28c6486242a99bfd6e9d2dabe09871c6b3b21b877?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a',
  },
  {
    nombre: 'Richel Manuel',
    energia: '921 kWh',
    email: 'rich....@gmail.com',
    fecha: '14/08/2024',
    estatus: 'ACTIVO',
    img: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d9dccc80e100b50cdc43acc28c6486242a99bfd6e9d2dabe09871c6b3b21b877?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a',
  },
];

function MisReferidos() {
  return (
    <div className="flex flex-col bg-white dark:bg-[#333844] p-6 rounded-xl shadow-md mt-4">
            <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex gap-1.5 items-start self-start">
          <div className="flex justify-center items-center w-10 h-10 rounded-full bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-lg">
            <IconUser size={24} strokeWidth={2} className="text-sky-500" />
          </div>
          <div className="text-2xl font-poppins-semibold tracking-tight leading-none text-gray-800 dark:text-gray-100 ml-2">
            Mis Referidos
          </div>
        </div>
        <div className="mt-1.5 text-sm leading-5 text-gray-600 dark:text-gray-400 max-md:max-w-full">
          El panel de control te permite ver la cantidad de referidos, quiénes han solicitado servicios, la energía generada por ellos, y el progreso de sus baterías. Todo en un solo lugar para que tengas un seguimiento fácil y completo.
        </div>
      </div>
      <div className="text-left text-2xl font-poppins-semibold text-gray-800 dark:text-gray-100 mb-4 mt-2">
        Lista de mis referidos
      </div>
      <table className="table-auto w-full text-left border-collapse">
        <thead>
          <tr className="text-gray-600 dark:text-gray-400 text-sm">
            <th className="p-4 text-center border-b border-gray-300 dark:border-gray-700">USER</th>
            <th className="p-4 text-center border-b border-gray-300 dark:border-gray-700">Email</th>
            <th className="p-4 text-center border-b border-gray-300 dark:border-gray-700">FECHA REFERIDO</th>
            <th className="p-4 text-center border-b border-gray-300 dark:border-gray-700">ESTATUS</th>
          </tr>
        </thead>
        <tbody>
          {referidos.map((ref, index) => (
            <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700 text-sm">
              <td className="p-4 text-center flex items-center justify-center space-x-3 border-b border-gray-300 dark:border-gray-700">
                <img
                  src={ref.img}
                  alt={ref.nombre}
                  className="rounded-full w-10 h-10 object-cover"
                />
                <div>
                  <div className="text-gray-800 dark:text-gray-100">{ref.nombre}</div>
                  <div className="text-lime-500 text-sm">{ref.energia}</div>
                </div>
              </td>
              <td className="p-4 text-center text-gray-600 dark:text-gray-400 border-b border-gray-300 dark:border-gray-700">
                {ref.email}
              </td>
              <td className="p-4 text-center text-gray-600 dark:text-gray-400 border-b border-gray-300 dark:border-gray-700">
                {ref.fecha}
              </td>
              <td className="p-4 text-center text-sky-500 font-poppins-semibold border-b border-gray-300 dark:border-gray-700">
                {ref.estatus}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MisReferidos;
