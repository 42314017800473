import { IconBolt, IconBattery, IconUserPlus, IconUsersGroup } from '@tabler/icons-react';

function PanelControl() {
  return (
    <div className="flex flex-col bg-white dark:bg-[#333844] rounded-xl shadow-md p-6 mt-4">
      <div className="flex items-center mb-4">
        <div className="flex justify-center items-center w-12 h-12 rounded-full bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-lg">
          <IconBolt size={32} strokeWidth={2} className="text-sky-500" />
        </div>
        <h2 className="ml-4 text-2xl font-poppins-semibold text-gray-800 dark:text-gray-100">Panel de Control</h2>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-400">
        El panel de control te permite ver la cantidad de referidos, quiénes han solicitado servicios, la energía generada por ellos, y el progreso de sus baterías. Todo en un solo lugar para que tengas un seguimiento fácil y completo.
      </p>
      <div className="flex mt-6 bg-gradient-to-r from-[#f0f4f8] to-[#e0e8ef] dark:from-[#2d3a45] dark:to-[#242a33] rounded-xl p-6 shadow-sm">
        <div className="flex flex-col justify-center w-1/2">
          <div className="mb-4">
            <div className="flex items-center text-gray-800 dark:text-gray-100">
              <IconBattery size={18} strokeWidth={2} className="text-sky-500 dark:text-sky-400 mr-1" />
              <span className="text-lg font-poppins-semibold text-sky-500 dark:text-sky-400">Nivel 1</span>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">105,4 kWh (25%)</p>
          </div>
          <div className="mb-4">
            <div className="flex items-center text-gray-400">
              <IconBattery size={18} strokeWidth={2} className="text-gray-400 mr-1" />
              <span className="text-lg font-poppins-semibold text-gray-400">Nivel 2</span>
            </div>
            <p className="text-sm text-gray-400 mt-1">0,0 kWh (0%)</p>
          </div>
          <div>
            <div className="flex items-center text-gray-400">
              <IconBattery size={18} strokeWidth={2} className="text-gray-400 mr-1" />
              <span className="text-lg font-poppins-semibold text-gray-400">Nivel 3</span>
            </div>
            <p className="text-sm text-gray-400 mt-1">0,0 kWh (0%)</p>
          </div>
        </div>
        <div className="flex items-center justify-center w-1/2">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/42c0e5c20f997a100a769efdd3eb1f6add50476d57d36889ff82e8969201f3a9"
            className="object-contain w-full max-w-[300px]"
            alt="Energy Meter"
          />
        </div>
      </div>
      <div className="flex justify-between mt-6 space-x-4">
        <div className="flex-1 p-4 bg-white dark:bg-gray-700 rounded-lg shadow-md">
          <p className="text-sm font-poppins-semibold text-gray-600 dark:text-gray-300">Tus ganancias</p>
          <div className="flex items-center text-lg font-poppins-bold text-gray-800 dark:text-gray-100">
            <IconUserPlus size={18} strokeWidth={2} className="mr-1 text-sky-500 dark:text-sky-400" />
            <span>$23,980</span>
          </div>
        </div>
        <div className="flex-1 p-4 bg-white dark:bg-gray-700 rounded-lg shadow-md">
          <p className="text-sm font-poppins-semibold text-gray-600 dark:text-gray-300">Total Referidos</p>
          <div className="text-lg font-poppins-bold text-gray-800 dark:text-gray-100">8</div>
        </div>
        <div className="flex-1 p-4 bg-white dark:bg-gray-700 rounded-lg shadow-md">
          <p className="text-sm font-poppins-semibold text-gray-600 dark:text-gray-300">Referidos que solicitaron SolarFlex</p>
          <div className="text-lg font-poppins-bold text-gray-800 dark:text-gray-100">2</div>
        </div>
      </div>
    </div>
  );
}

export default PanelControl;
