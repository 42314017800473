import React, { useEffect } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import Consumo from "../components/monitoreo/Consumo.js";
import Clima from "../components/monitoreo/Clima.js";
import Produccion from "../components/monitoreo/Produccion.js";
import Tendencia from "../components/monitoreo/Tendencias.js";
import { useParams } from "react-router-dom";

function Monitorizacion() {
  const params = useParams();
  const id = params.uid;

  return (
    <DashboardLayout>
      <div className="dark:bg-gray-900 dark:text-white bg-transparent text-slate-500 w-full">

        <div className="flex flex-col lg:flex-row gap-3 mt-4 w-full">
          <Consumo />
          <Clima/>
        </div>

        <div className="flex flex-col lg:flex-row gap-5 mt-4 w-full">
          <Produccion />
          <Tendencia id={id}/>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Monitorizacion;
