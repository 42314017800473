import React from "react";
import img from "../../assets/img/smart-home-11.png";
import imgLogo from "../../assets/img/logo-w-white.png";
import imgArrows from "../../assets/img/arrows.svg";

function Consumo() {
  return (
    <section className="flex flex-col gap-y-3 rounded-[9px] border border-solid border-white/10 h-auto bg-[#F4F7FB] dark:bg-[#282C34] p-3 w-full xl:w-6/12 2xl:w-7/12">
      <nav className="flex flex-col items-center w-full sm:flex-row sm:justify-between sm:items-start mb-2">
        <div className="font-open-sans text-xs font-poppins-semibold leading-[1.63] tracking-[-0.6px] text-zinc-700/60 dark:text-white mb-2 sm:mb-0">
          Planta Nro 1
        </div>
        <div className="font-helvetica flex items-center justify-center w-full sm:w-auto rounded-[9px] bg-gray-700 py-[1.9px] pl-[3px] pr-[1.9px] text-center text-[9px] leading-tight tracking-[0px] [box-shadow:inset_-1.49px_-1.49px_1.5px_0px_rgba(255,255,255,0.15),inset_1.5px_1.5px_3px_0px_rgba(0,0,0,0.6)]">
          <div className="flex flex-col items-center justify-end gap-y-[3.4px] rounded-md border-l-[0.88px] border-r-[0.88px] border-t-[0.88px] border-solid border-neutral-600 px-8 pb-[3.2px] pt-[6.6px] drop-shadow-lg [background-image:linear-gradient(0deg,_#006199,_#00a1ff)] [border-bottom-width:0.88px]">
            <button className="text-center text-white">Lounge</button>
            <div className="flex items-center justify-center text-center">
              <div className="flex items-center">
                <div className="flex h-full w-full flex-shrink-0 items-center justify-center overflow-clip px-[9px] text-center">
                  <div className="h-0 w-0 flex-shrink-0 bg-zinc-800"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-[103px] flex-shrink-0 flex-col items-center justify-end gap-y-[3.4px] rounded-md px-9 pb-[5.2px] pt-[8.6px] drop-shadow-lg">
            <button className="self-stretch text-center text-slate-400">
              Office
            </button>
            <div className="flex items-center justify-center text-center">
              <div className="flex items-center">
                <div className="flex h-full w-full flex-shrink-0 items-center justify-center overflow-clip px-[9px] text-center">
                  <div className="h-0 w-0 flex-shrink-0 bg-[silver]"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-[102px] flex-shrink-0 flex-col items-center">
            <div className="mr-[0.75px] flex w-[103px] flex-col items-center justify-end gap-y-[3.4px] rounded-md px-[5.2px] pb-[5.2px] pt-[8.6px] text-center drop-shadow-lg">
              <button className="self-stretch text-slate-400">Attic</button>
              <div className="flex items-center justify-center">
                <div className="flex items-center text-center">
                  <div className="flex h-full w-full flex-shrink-0 items-center justify-center overflow-clip px-[9px]">
                    <div className="h-0 w-0 flex-shrink-0 bg-[silver] text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "9px",
        }}
        className="h-full w-full p-2 flex items-center justify-end"
      >
        <div className="w-full max-w-sm">
          <div className="mb-3 flex flex-col justify-center items-center h-80">
            <div className="relative flex flex-col items-center">
              <div className="bg-black/80 rounded-lg mb-8 p-2">
                <div class="relative size-24">
                  <svg class="rotate-[135deg] size-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-transparent" stroke-width="1.5" stroke-dasharray="100 100" stroke-linecap="round"></circle>

                    <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-blue-600 shadow-sm" stroke-width="1.5" stroke-dasharray="37.5 100" stroke-linecap="round"></circle>
                  </svg>

                  <div class="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-black rounded-full px-7 py-6">
                    <span class="text-[14px] text-white leading-3 block mb-1.5">KW</span>
                    <span class="text-xl text-white leading-3 font-bold block">1.5</span>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-x-2">
                  <div className="bg-black rounded-full w-6 h-6 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#00A1FF" stroke="#00A1FF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-bolt"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" /></svg>
                  </div>
                  <div>
                    <span className="text-[10px] text-white leading-3 block mb-1.5">105 kW</span>
                    <span className="text-[10px] text-white leading-3 block">Capacidad</span>
                  </div>
                </div>
              </div>
              <div className="absolute -bottom-3 -left-[88px] lg:-left-24 bg-black/80 backdrop-blur-sm rounded-full w-20 h-20 flex flex-col justify-center items-center">
                <div><span class="text-xl text-white leading-3 font-bold">6.5 </span><span className="text-[10px]">kW</span></div>
                <span class="text-[10px] text-white leading-3 block mb-1.5">Consumo</span>
              </div>
              <div className="bg-black/80 backdrop-blur-sm w-16 h-16 flex justify-center items-center rounded-lg shadow-sm">
                <img src={imgLogo} alt="img" className="w-10 drop-shadow-[0_0_5px_rgba(0,161,255,1)]" />
              </div>
              <div>
                <div className="absolute -bottom-3 -right-[88px] lg:-right-24 bg-black/80 backdrop-blur-sm rounded-full w-20 h-20 flex flex-col justify-center items-center">
                  <div><span class="text-xl text-white leading-3 font-bold">4.8 </span><span className="text-[10px]">kW</span></div>
                  <span class="text-[10px] text-white leading-3 block mb-1.5">Importado</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center lg:justify-between bg-black/50 backdrop-blur-sm rounded-lg p-3 gap-3">
            <div className="flex flex-col justify-center items-center">
              <div className="text-xs text-center">
                Consumo Planta
              </div>
              <div className="text-sm font-poppins-bold text-center">
                105 kW
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-xs text-center">
                Ahorro
              </div>
              <div className="text-sm font-poppins-bold text-green-400 text-center">
                40%
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-xs text-center">
                Cuota Mensual
              </div>
              <div className="text-sm font-poppins-bold text-[#00A1FF] text-center">
                $ 1,211,620,000
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Consumo;
