import React from 'react';
import { IconChevronDown } from '@tabler/icons-react';

function PreguntasFrecuentes() {
  return (
    <div className="flex flex-col bg-white dark:bg-[#333844] p-6 rounded-xl shadow-md mt-4">
      <div className="flex gap-2 items-start text-center">
        <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-lg">
          <IconChevronDown size={24} strokeWidth={2} className="text-sky-500" />
        </div>
        <div className="text-2xl font-poppins-semibold leading-tight text-gray-700 dark:text-gray-100">
          Preguntas Frecuentes
        </div>
      </div>

      <div className="flex flex-col mt-10 max-md:max-w-full">
        <div className="flex flex-col pb-7 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full text-sm font-poppins-semibold leading-tight text-gray-600 dark:text-gray-400 max-md:max-w-full">
            <div>¿Cómo funciona el Plan de Referidos SolarFlex?</div>
            <IconChevronDown size={16} strokeWidth={2} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="mt-2.5 w-full min-h-0 border border-solid bg-gray-300 dark:bg-gray-700 border-gray-300 dark:border-gray-700 max-md:max-w-full"></div>
          <div className="mt-3 w-full text-xs leading-4 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
            El Plan de Referidos SolarFlex te permite ganar energía acumulada invitando a tus amigos y familiares a solicitar el servicio SolarFlex. Cuando ellos instalen el sistema y empiecen a generar energía, esa energía se reflejará en tus Baterías Digitales dentro de la app de Watti. Al llenar tus baterías, podrás redimir la energía por premios y beneficios exclusivos.
          </div>
        </div>

        <div className="flex flex-col pb-6 mt-7 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full text-sm font-poppins-semibold leading-tight text-gray-600 dark:text-gray-400 max-md:max-w-full">
            <div>¿Qué debo hacer para empezar a acumular energía con mis referidos?</div>
            <IconChevronDown size={16} strokeWidth={2} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="mt-2.5 w-full border border-solid bg-gray-300 dark:bg-gray-700 border-gray-300 dark:border-gray-700 min-h-[1px] max-md:max-w-full"></div>
          <div className="mt-3 w-full text-xs leading-4 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
            Para comenzar a acumular energía, comparte tu enlace de referido con amigos y familiares. Una vez que ellos soliciten e instalen el servicio SolarFlex, la energía generada por sus sistemas empezará a llenar tus Baterías Digitales en la app.
          </div>
        </div>

        <div className="flex flex-col pb-4 mt-7 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full text-sm font-poppins-semibold leading-tight text-gray-600 dark:text-gray-400 max-md:max-w-full">
            <div>¿Qué sucede si mis referidos no solicitan el servicio SolarFlex?</div>
            <IconChevronDown size={16} strokeWidth={2} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="mt-2.5 w-full border border-solid bg-gray-300 dark:bg-gray-700 border-gray-300 dark:border-gray-700 min-h-[1px] max-md:max-w-full"></div>
          <div className="mt-3 w-full text-xs leading-4 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
            Si tus referidos no solicitan el servicio SolarFlex, no podrás acumular energía en tus Baterías Digitales. Es necesario que ellos instalen el sistema SolarFlex para que la energía generada se refleje en tu cuenta.
          </div>
        </div>

        <div className="flex flex-col pb-6 mt-7 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full text-sm font-poppins-semibold leading-tight text-gray-600 dark:text-gray-400 max-md:max-w-full">
            <div>¿Cómo se llenan las Baterías Digitales y qué beneficios obtengo?</div>
            <IconChevronDown size={16} strokeWidth={2} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="mt-2.5 w-full border border-solid bg-gray-300 dark:bg-gray-700 border-gray-300 dark:border-gray-700 min-h-[1px] max-md:max-w-full"></div>
          <div className="mt-3 w-full text-xs leading-4 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
            Tus Baterías Digitales se llenan con la energía generada por los sistemas SolarFlex de tus referidos. Por cada nuevo referido que solicite el servicio, tu batería actual se cargará un 5%. Además, si tus referidos invierten en Watti Smart City, también recibirás un porcentaje adicional de carga. Al llenar tus baterías, podrás redimir la energía por premios y beneficios especiales.
          </div>
        </div>

        <div className="flex flex-col pb-9 mt-7 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full text-sm font-poppins-semibold leading-tight text-gray-600 dark:text-gray-400 max-md:max-w-full">
            <div>¿Cuántos niveles de Baterías Digitales existen y cómo puedo subir de nivel?</div>
            <IconChevronDown size={16} strokeWidth={2} className="text-gray-600 dark:text-gray-400" />
          </div>
          <div className="mt-2.5 w-full border border-solid bg-gray-300 dark:bg-gray-700 border-gray-300 dark:border-gray-700 min-h-[1px] max-md:max-w-full"></div>
          <div className="mt-3 w-full text-xs leading-4 text-justify text-gray-600 dark:text-gray-400 max-md:max-w-full">
            Hay tres niveles de Baterías Digitales en el Plan de Referidos SolarFlex. Entre más referidos tengas y más energía generen, más rápido se llenarán tus baterías, permitiéndote subir de nivel y acceder a mejores recompensas. Cada nivel ofrece diferentes premios y beneficios, por lo que acumular más energía te permitirá obtener recompensas más exclusivas.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreguntasFrecuentes;
