import React, { useEffect, useState } from "react";
import axios from "axios";
import bateriaLight from "../../assets/img/icons/bateria-light.svg";
import bateriaDark from "../../assets/img/icons/bateria-dark.svg";
import consumoLight from "../../assets/img/icons/consumo-light.svg";
import consumoDark from "../../assets/img/icons/consumo-dark.svg";
import produccionLight from "../../assets/img/icons/produccion-light.svg";
import produccionDark from "../../assets/img/icons/produccion-dark.svg";
import exportadoLight from "../../assets/img/icons/exportado-light.svg";
import exportadoDark from "../../assets/img/icons/exportado-dark.svg";
import centerIcon from "../../assets/img/icons/center.png";
import ClimaComponent from "../Clima";

function Clima() {
  return (
    <div className="flex flex-col w-full xl:w-6/12 2xl:w-5/12">
      <ClimaComponent />
      <div className="z-0 flex flex-col-2 items-center justify-center gap-4 relative">
        <div className="absolute inset-0 flex justify-center items-center">
          <img src={centerIcon} className="z-[1] h-24 w-24 flex-shrink-0 ml" />
        </div>
        <div className="flex flex-wrap w-full gap-4">
          <div className="relative flex flex-col items-start w-full justify-end gap-y-1 rounded-t-[11px] rounded-bl-[11px] rounded-br-[75px] border border-solid border-white/50 pl-2.5 pr-10 py-2.5 drop-shadow-lg bg-gradient-to-br from-white/10 to-[#00A1FF]/30">
            <aside className="z-[2] text-[12px] lg:text-[20px] leading-none text-neutral-500 dark:text-white">
              Producción Solar
            </aside>
            <div className="z-[3] flex items-center justify-center gap-x-[5.6px]">
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  181,3 KW
                </div>
                <div className="text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Hoy</div>
                  <div>kWh</div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  3,9 KW
                </div>
                <div className="flex flex-col items-center text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Total</div>
                  <div>kWh</div>
                </div>
              </div>
            </div>
            <div className="flex justify-start w-full">
              <img
                src={produccionLight}
                className="z-[1] h-11 w-11 flex-shrink-0 ml block dark:hidden"
              />
              <img
                src={produccionDark}
                className="z-[1] h-11 w-11 flex-shrink-0 ml hidden dark:block"
              />
            </div>
          </div>

          <div className="relative flex flex-col items-start w-full justify-center gap-y-1 rounded-b-[11px] rounded-tl-[11px] rounded-tr-[75px] border border-solid border-white/50 pl-2.5 pr-11 py-2.5 drop-shadow-lg bg-gradient-to-tr from-white/10 to-[#00A1FF]/30">
            <div className="flex justify-start w-full">
              <img
                src={exportadoLight}
                className="z-[1] h-11 w-11 flex-shrink-0 ml block dark:hidden"
              />
              <img
                src={exportadoDark}
                className="z-[1] h-11 w-11 flex-shrink-0 ml hidden dark:block"
              />
            </div>
            <aside className="z-[2] text-[12px] lg:text-[20px] leading-none text-neutral-500 dark:text-white">
              Exportado
            </aside>
            <div className="z-[3] flex items-center justify-center gap-x-[5.6px]">
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  14,6 KW
                </div>
                <div className="text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Hoy</div>
                  <div>kWh</div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  8,3 KW
                </div>
                <div className="flex flex-col items-center text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Total</div>
                  <div>kWh</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full gap-4">
          <div className="relative flex flex-col items-start w-full justify-end gap-y-1 rounded-t-[11px] rounded-bl-[75px] rounded-br-[11px] border border-solid border-white/50 pl-10 pr-2.5 py-2.5 drop-shadow-lg bg-gradient-to-bl from-white/10 to-[#00A1FF]/30">
            <aside className="z-[2] text-[12px] lg:text-[20px] leading-none text-neutral-500 dark:text-white text-right w-full">
              Importado
            </aside>
            <div className="z-[3] flex justify-end text-right w-full">
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  115,0 KW
                </div>
                <div className="text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Hoy</div>
                  <div>kWh</div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  4,7 KW
                </div>
                <div className="flex flex-col items-center text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Total</div>
                  <div>kWh</div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full">
              <img
                src={bateriaLight}
                className="z-[1] h-11 w-11 flex-shrink-0 ml block dark:hidden"
              />
              <img
                src={bateriaDark}
                className="z-[1] h-11 w-11 flex-shrink-0 ml hidden dark:block"
              />
            </div>
          </div>
          <div className="relative flex flex-col items-end w-full justify-end gap-y-1 rounded-b-[11px] rounded-tl-[75px] rounded-tr-[11px] border border-solid border-white/50 pl-10 pr-2.5 py-2.5 drop-shadow-lg bg-gradient-to-tl from-white/90 dark:from-white/10 to-[#00A1FF]/30 dark:to-[#00A1FF]/30">
            <div className="flex justify-end w-full">
              <img
                src={consumoLight}
                className="z-[1] h-11 w-11 flex-shrink-0 ml block dark:hidden"
              />
              <img
                src={consumoDark}
                className="z-[1] h-11 w-11 flex-shrink-0 ml hidden dark:block"
              />
            </div>
            <aside className="z-[2] text-[12px] lg:text-[20px] leading-none text-neutral-500 dark:text-white text-right w-full">
              Consumo
            </aside>
            <div className="z-[3] flex justify-end text-right w-full">
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  281,7 KW
                </div>
                <div className="text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Hoy</div>
                  <div>kWh</div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-[2.8px]">
                <div className="text-[12px] lg:text-[15px] leading-[1.4] text-neutral-500 dark:text-white">
                  8,3 KW
                </div>
                <div className="flex flex-col items-center text-[7px] leading-none text-neutral-400 dark:text-neutral-200">
                  <div>Total</div>
                  <div>kWh</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clima;
