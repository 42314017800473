import React, { useState } from "react";
import { IconChevronLeft, IconId } from "@tabler/icons-react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";

function CallForm({ onBack }) {
  const [formValues, setFormValues] = useState({
    identificationType: "CC - Cédula de Ciudadanía",
    documentNumber: "",
    name: "",
    email: "",
    phone: "+57",
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar la lógica de envío del formulario
    console.log("Form submitted:", formValues);
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center pt-12 bg-white dark:bg-gray-900 text-black dark:text-white">
        <div className="flex flex-col max-w-full font-medium w-[982px]">
          <div className="justify-center text-4xl leading-none text-center max-md:max-w-full">
            Queremos ofrecerte la mejor asesoría
          </div>
          <div className="justify-center text-center mt-5 text-lg leading-7 max-md:max-w-full">
            Para asegurarnos de que recibas la atención que mereces, por favor
            completa la información a continuación y te llamaremos en el momento
            que mejor te convenga. ¡Estamos aquí para asistirte!
          </div>
        </div>
        <div className="flex flex-col mt-12 max-w-full w-[982px] max-md:mt-10 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          {/* Botón para regresar */}
          <div className="mb-4">
            <button
              onClick={onBack}
              className="flex items-center text-sky-500 hover:text-sky-700"
            >
              <IconChevronLeft className="w-6 h-6" />
              <span className="ml-2">Volver</span>
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Tipo de Identificación */}
            <div className="flex flex-col w-full max-md:max-w-full">
              <label
                htmlFor="identificationType"
                className="text-gray-800 dark:text-gray-200 max-md:max-w-full"
              >
                Tipo de identificación
              </label>
              <select
                    id="identificationType"
                    name="identificationType"
                    value={formValues.identificationType}
                    onChange={handleChange}
                    className="w-full bg-gray-200 dark:bg-gray-700 bg-opacity-10 text-black dark:text-white px-4 py-2 rounded-md appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-sky-500"
                  >
                    <option value="">Selecciona una Opcion</option>
                    <option value="CC - Cédula de Ciudadanía">
                      CC - Cédula de Ciudadanía
                    </option>
                    <option value="TI - Tarjeta de Identidad">
                      TI - Tarjeta de Identidad
                    </option>
                    <option value="NIT">NIT</option>
                  </select>
            </div>

            {/* Número de Documento */}
            <div className="flex flex-col mt-4 w-full max-md:max-w-full">
              <label
                htmlFor="documentNumber"
                className="text-gray-800 dark:text-gray-200 max-md:max-w-full"
              >
                Número de documento
              </label>
              <input
                type="text"
                id="documentNumber"
                name="documentNumber"
                value={formValues.documentNumber}
                onChange={handleChange}
                className="px-6 mt-1.5 w-full rounded-lg bg-gray-200 dark:bg-gray-700 bg-opacity-10 min-h-[43px] text-black dark:text-white max-md:px-5 max-md:max-w-full"
                placeholder="Número de documento"
              />
            </div>

            {/* Nombre */}
            <div className="flex flex-col mt-4 w-full max-md:max-w-full">
              <label
                htmlFor="name"
                className="text-gray-800 dark:text-gray-200 max-md:max-w-full"
              >
                Nombre:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                className="px-6 mt-1.5 w-full rounded-lg bg-gray-200 dark:bg-gray-700 bg-opacity-10 min-h-[43px] text-black dark:text-white max-md:px-5 max-md:max-w-full"
                placeholder="Nombre completo"
              />
            </div>

            {/* Correo */}
            <div className="flex flex-col mt-4 w-full max-md:max-w-full">
              <label
                htmlFor="email"
                className="text-gray-800 dark:text-gray-200 max-md:max-w-full"
              >
                Correo:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                className="px-6 mt-1.5 w-full rounded-lg bg-gray-200 dark:bg-gray-700 bg-opacity-10 min-h-[43px] text-black dark:text-white max-md:px-5 max-md:max-w-full"
                placeholder="Ej: nombre@gmail.com"
              />
            </div>

            {/* Teléfono */}
            <div className="flex flex-col mt-4 w-full max-md:max-w-full">
              <label
                htmlFor="phone"
                className="text-gray-800 dark:text-gray-200 max-md:max-w-full"
              >
                Teléfono:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formValues.phone}
                onChange={handleChange}
                className="px-6 mt-1.5 w-full rounded-lg bg-gray-200 dark:bg-gray-700 bg-opacity-10 min-h-[43px] text-black dark:text-white max-md:px-5 max-md:max-w-full"
                placeholder="+57"
              />
            </div>

            {/* Aceptar términos */}
            <div className="flex flex-col mt-6 w-full max-md:max-w-full">
              <div className="flex gap-2.5 items-center self-start max-md:max-w-full">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formValues.termsAccepted}
                  onChange={handleChange}
                  className="rounded border-gray-300 dark:border-gray-600 dark:bg-gray-800"
                />
                <div className="self-stretch my-auto text-base tracking-tight leading-none text-black dark:text-gray-300 w-[404px]">
                  He leído y acepto{" "}
                  <span className="text-blue-600 dark:text-blue-300 underline">
                    el tratamiento de mis datos personales
                  </span>
                </div>
              </div>
              <div className="mt-6 w-full text-xl font-semibold leading-none text-gray-50 whitespace-nowrap max-md:max-w-full justify-center items-center flex">
                <button
                  type="submit"
                  className="gap-3 self-stretch px-6 py-4 max-w-full rounded-xl bg-sky-500 text-white hover:bg-sky-700 shadow-[-2px_-2px_4px_rgba(255,255,255,0.25)] w-[363px] max-md:px-5"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CallForm;
