import axios from "axios";
import React, { useEffect, useState } from "react";

function ClimaComponent() {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const API_KEY = "b870ec793c1d7634008e7e7c4880ca5c";
  const ciudad = "Cúcuta";
  const country = "CO";

  useEffect(() => {
    const fetchWeather = async () => {
      const storedWeatherData = JSON.parse(sessionStorage.getItem("weatherData"));
      const lastFetchTime = sessionStorage.getItem("lastFetchTime");
      const currentTime = new Date().getTime();

      if (storedWeatherData && lastFetchTime && currentTime - lastFetchTime < 3600000) {
        setWeatherData(storedWeatherData);
        setLoading(false);
      } else {
        try {
          const response = await axios.get(
            `https://api.openweathermap.org/data/2.5/weather?q=${ciudad},${country}&appid=${API_KEY}&units=metric&lang=es`
          );
          setWeatherData(response.data);
          setLoading(false);
          sessionStorage.setItem("weatherData", JSON.stringify(response.data));
          sessionStorage.setItem("lastFetchTime", currentTime);
        } catch (error) {
          console.error("Error fetching the weather data", error);
        }
      }
    };

    fetchWeather();
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const { main, weather, name } = weatherData;
  const weatherIcon = weather[0].icon; 
  const weatherDescription = weather[0].description;
  
  const weatherImages = {
    "01d": "https://example.com/sunny-day-image.jpg", // Día soleado
    "01n": "https://example.com/clear-night-image.jpg", // Noche despejada
    "02d": "https://example.com/partly-cloudy-day-image.jpg", // Parcialmente nublado día
    "02n": "https://example.com/partly-cloudy-night-image.jpg", // Parcialmente nublado noche
    "03d": "https://example.com/cloudy-day-image.jpg", // Nublado día
    "03n": "https://example.com/cloudy-night-image.jpg", // Nublado noche
    "09d": "https://example.com/rainy-day-image.jpg", // Lluvia día
    "09n": "https://example.com/rainy-night-image.jpg", // Lluvia noche
    "10d": "https://example.com/rainy-day-image.jpg", // Lluvia día
    "10n": "https://example.com/rainy-night-image.jpg", // Lluvia noche
    "11d": "https://example.com/thunderstorm-day-image.jpg", // Tormenta eléctrica día
    "11n": "https://example.com/thunderstorm-night-image.jpg", // Tormenta eléctrica noche
    "13d": "https://example.com/snow-day-image.jpg", // Nieve día
    "13n": "https://example.com/snow-night-image.jpg", // Nieve noche
    "50d": "https://example.com/mist-day-image.jpg", // Neblina día
    "50n": "https://example.com/mist-night-image.jpg", // Neblina noche
  };
  
  const backgroundStyle = {
    backgroundImage: `url(${weatherImages[weatherIcon] || 'https://example.com/default-weather-image.jpg'})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className="relative z-0 justify-center rounded-xl px-6 py-3 w-full mb-4"
      style={backgroundStyle}
    >
      <div className="absolute inset-0 -z-10 max-h-full max-w-full rounded-xl bg-no-repeat [background-position:0.85px_-152.09px] [background-size:100%_282%]" />
      <div className="absolute inset-0 -z-10 rounded-xl bg-[#143A85]/60" />

      <div className="flex flex-wrap justify-between items-center gap-4 pr-1 pt-3.5 z-10">
        <div className="mb-[-13.5px] text-[11px] leading-normal text-gray-200">
          Clima hoy
        </div>
        <div className="h-10 w-10">
          <img
            src={`http://openweathermap.org/img/wn/${weatherIcon}@2x.png`}
            alt={weatherDescription}
            className="h-full w-full"
          />
        </div>
      </div>
      <div className="z-10 flex justify-between items-center gap-4 pt-[2.5px] text-white leading-[1.37] tracking-[-0.6px]">
        <div className="flex flex-col items-center justify-center rounded-lg border border-solid border-white/10 bg-white/5 p-2 drop-shadow-lg backdrop-blur-sm">
          <div className="text-center text-xs leading-[1.37]">
            {name}, Norte de Santander - {main.temp}°C
          </div>
        </div>
        <div className="text-[15px] leading-[1.37]">
          {weatherDescription.charAt(0).toUpperCase() +
            weatherDescription.slice(1)}
        </div>
      </div>
    </div>
  );
}

export default ClimaComponent;
