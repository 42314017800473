import React, { useState, useEffect } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import axios from "axios";
import Swal from "sweetalert2";
import 'tailwindcss/tailwind.css';
import { useNavigate } from "react-router-dom";

const SolicitudVisita = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const navigate = useNavigate();
  const fetchTimeSlots = async (date) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    try {
      const response = await axios.get(`https://api.watti.app/api/horario/por-dia/${date}`, config);
      setTimeSlots(response.data);
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchTimeSlots(selectedDate);
    }
  }, [selectedDate]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTimeSlot(""); // Reset selected time slot when date changes
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleSubmit = async (selectedSlotId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/horario/${selectedSlotId}`,
        { is_libre: false },
        config
      );

      if (response.status === 200) {
        Swal.fire('Cita confirmada con éxito.', '', 'success');
        navigate("/dashboard");
      } else {
        Swal.fire('Error al confirmar la cita.', '', 'error');
      }
    } catch (error) {
      console.error("Error confirming appointment:", error);
      Swal.fire('Error al confirmar la cita.', '', 'error');
    }
  };

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const renderCalendar = () => {
    const today = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const daysInMonth = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    const weekDays = ["D", "L", "M", "M", "J", "V", "S"];

    const calendarDays = [];

    // Fill empty days before the start of the month
    for (let i = 0; i < startOfMonth.getDay(); i++) {
      calendarDays.push(<div key={`empty-${i}`} className="py-1"></div>);
    }

    // Fill the days of the month
    daysInMonth.forEach((date, index) => {
      const isPastDate = date < today && date.toDateString() !== today.toDateString();
      const isSunday = date.getDay() === 0;
      const isToday = date.toISOString().split("T")[0] === today.toISOString().split("T")[0];
      calendarDays.push(
        <button
          key={index}
          onClick={() => !isPastDate && !isSunday && handleDateSelect(date.toISOString().split("T")[0])}
          className={`py-1 ${selectedDate === date.toISOString().split("T")[0]
            ? "bg-blue-500 text-white"
            : isToday
            ? "bg-green-500 text-white"
            : "hover:bg-gray-300 dark:hover:bg-gray-600"} ${isPastDate ? "text-red-500 cursor-not-allowed" : ""} ${isSunday ? "text-red-500 cursor-not-allowed" : ""}`}
          disabled={isPastDate || isSunday}
        >
          {date.getDate()}
        </button>
      );
    });

    return (
      <div className="bg-gray-100 dark:bg-gray-700 rounded-xl p-4 text-center">
        <div className="flex justify-between items-center mb-4">
          <button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))}>
            &lt;
          </button>
          <h3 className="text-lg font-poppins-semibold text-gray-800 dark:text-white">
            {currentDate.toLocaleString('es-ES', { month: 'long', year: 'numeric' })}
          </h3>
          <button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))}>
            &gt;
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1 text-gray-600 dark:text-gray-400 mt-4">
          {weekDays.map((day, index) => (
            <div key={index} className="text-center font-poppins-semibold">{day}</div>
          ))}
          {calendarDays}
        </div>
      </div>
    );
  };

  const confirmSubmission = (e) => {
    e.preventDefault();
    if (!selectedDate || !selectedTimeSlot) {
      Swal.fire('Error', 'Por favor, selecciona una fecha y un horario que esté disponible.', 'error');
      return;
    }

    const selectedSlot = timeSlots.find(slot => slot.descripcion === selectedTimeSlot);

    if (!selectedSlot || !selectedSlot.is_libre) {
      Swal.fire('Error', 'El horario seleccionado no está disponible. Por favor, seleccione otro horario.', 'error');
      return;
    }

    Swal.fire({
      title: 'Confirmar datos',
      html: `
        <strong>Fecha:</strong> ${new Date(selectedDate).toLocaleDateString("es-ES")}<br/>
        <strong>Horario:</strong> ${selectedTimeSlot}
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(selectedSlot.id);
      }
    });
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center px-5 py-10">
        <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-xl shadow-md p-6">
          <h2 className="text-2xl font-poppins-bold text-center text-gray-800 dark:text-white">
            Indica el día de la visita técnica
          </h2>
          <p className="text-center text-gray-600 dark:text-gray-400 mt-2">
            Selecciona una fecha y un horario para tu visita técnica.
          </p>
          <div className="flex justify-between items-center py-4 mt-4 text-xs text-gray-600 dark:text-gray-400">
            <div className="flex items-center">
              <div className="bg-gray-300 h-4 w-4 rounded-full mr-2"></div>
              Ocupada
            </div>
            <div className="flex items-center">
              <div className="bg-blue-500 h-4 w-4 rounded-full mr-2"></div>
              Disponible
            </div>
            <div className="flex items-center">
              <div className="bg-red-500 h-4 w-4 rounded-full mr-2"></div>
              No laborales
            </div>
          </div>
          {renderCalendar()}
          <div className="mt-6 text-xl text-gray-800 dark:text-white">{selectedDate ? selectedDate : "Selecciona una fecha"}</div>
          {timeSlots.length > 0 ? (
            timeSlots.map((slot) => (
              <button
                key={slot.id}
                type="button"
                onClick={() => handleTimeSlotChange(slot.descripcion)}
                className={`w-full py-2 mt-2 rounded-lg border ${selectedTimeSlot === slot.descripcion
                  ? "bg-blue-500 text-white"
                  : slot.is_libre
                  ? "border-gray-700 text-gray-700 dark:border-gray-300 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                  : "bg-red-500 text-white cursor-not-allowed"}`}
                disabled={!slot.is_libre}
              >
                {slot.descripcion}
              </button>
            ))
          ) : (
            <div className="text-red-500 mt-4">No hay horarios disponibles</div>
          )}
          <button
            onClick={(e) => confirmSubmission(e)}
            className="w-full py-2 mt-6 rounded-lg bg-blue-500 text-white font-poppins-semibold shadow-md hover:bg-blue-700"
          >
            Confirmar Cita
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SolicitudVisita;
