import React, { useState, useEffect } from "react";
import { InfoOutlined } from "@mui/icons-material";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgress } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const Tendencia = ({ id }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("Día");
  const [chartData, setChartData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getSessionStorageKey = (date, id) => `${id}-${date.toISOString().split('T')[0]}`;

  const fetchData = async (date, forceUpdate = false) => {
    const storageKey = getSessionStorageKey(date, id);
    const cachedData = sessionStorage.getItem(storageKey);

    if (cachedData && !forceUpdate) {
      const parsedCachedData = JSON.parse(cachedData);
      setChartData(parsedCachedData);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      const fecha = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      const planta_id = id;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/monitor2/get-data?fecha=${fecha}&planta_id=${planta_id}`);
      console.log(response);
      // Assuming response.data.message contains the desired dataset
      const data = response.data.message;
      
      if (data.length === 0) {
        setErrorMessage("No hay datos para este día.");
      } else {
        // Update sessionStorage if data has changed
        if (JSON.stringify(data) !== cachedData) {
          sessionStorage.setItem(storageKey, JSON.stringify(data));
        
        }
        setChartData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("Error al obtener datos. Por favor, inténtelo de nuevo más tarde.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate, id]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getChartData = () => {
    const labels = chartData.map((item) => item.x);
    const dataValues = chartData.map((item) => item.y);

    return {
      labels,
      datasets: [
        {
          label: 'Radiación (W/m²)',
          data: dataValues,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: true,
          tension: 0.4,
          pointRadius: 3
        },
      ],
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Radiación: ${context.parsed.y} W/m²`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hora',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Radiación (W/m²)',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <section className="flex w-full lg:w-8/12 flex-col rounded-lg border border-solid border-white/10 bg-slate-100 dark:bg-[#282C34] p-3 drop-shadow-lg">
      <div className="flex flex-wrap items-center justify-between gap-y-[9px] sm:flex-nowrap">
        <div className="text-[15px] font-poppins-semibold tracking-[-0.6px] dark:text-white">
          Tendencia Energética
        </div>
        <div className="h-[18px] w-[18px] flex items-center">
          {/* <InfoOutlined /> */}
        </div>
      </div>
      <div className="my-4 flex flex-col lg:flex-row justify-center lg:justify-between">

      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between my-4 space-y-2 lg:space-y-0">
        <div className="">
          <div className="flex items-center rounded-md bg-gray-200 text-[9.6px] leading-3 text-zinc-700/60 [box-shadow:inset_-2.13px_-2.13px_2.1px_0px_rgba(255,255,255,0.2),inset_0px_2.8px_3.2px_0px_rgba(0,0,0,0.6)] dark:bg-[#202122]">
            <div className="flex items-center justify-center gap-x-3 py-1 px-2">
              <button
                className={`text-xs flex items-center justify-center rounded-md px-2 py-1 drop-shadow-lg ${
                  selectedPeriod === "Día"
                    ? "bg-sky-500 text-neutral-50"
                    : "bg-gray-300 text-gray-500 dark:bg-gray-700 dark:text-gray-300 hover:bg-sky-500 hover:text-neutral-50"
                }`}
                onClick={() => setSelectedPeriod("Día")}
              >
                Día
              </button>
              <button
                className={`text-xs flex items-center justify-center rounded-md px-2 py-1 drop-shadow-lg ${
                  selectedPeriod === "Semana"
                    ? "bg-sky-500 text-neutral-50"
                    : "bg-gray-300 text-gray-500 dark:bg-gray-700 dark:text-gray-300 hover:bg-sky-500 hover:text-neutral-50"
                }`}
                onClick={() => setSelectedPeriod("Semana")}
              >
                Semana
              </button>
              <button
                className={`text-xs flex items-center justify-center rounded-md px-2 py-1 drop-shadow-lg ${
                  selectedPeriod === "Mes"
                    ? "bg-sky-500 text-neutral-50"
                    : "bg-gray-300 text-gray-500 dark:bg-gray-700 dark:text-gray-300 hover:bg-sky-500 hover:text-neutral-50"
                }`}
                onClick={() => setSelectedPeriod("Mes")}
              >
                Mes
              </button>
              <button
                className={`text-xs flex items-center justify-center rounded-md px-2 py-1 drop-shadow-lg ${
                  selectedPeriod === "Año"
                    ? "bg-sky-500 text-neutral-50"
                    : "bg-gray-300 text-gray-500 dark:bg-gray-700 dark:text-gray-300 hover:bg-sky-500 hover:text-neutral-50"
                }`}
                onClick={() => setSelectedPeriod("Año")}
              >
                Año
              </button>
            </div>
          </div>
        </div>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          maxDate={new Date()}
          className="text-center p-1 border-none rounded-md bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 text-sm"
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress color="primary" />
        </div>
      ) : errorMessage ? (
        <div className="flex justify-center items-center h-64 text-red-500">
          {errorMessage}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full pl-[6.6px] pr-[6.5px]">
          <div className="w-full h-72">
            <Line data={getChartData()} options={options} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Tendencia;
