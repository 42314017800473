import { combineReducers } from 'redux';
import auth from './auth';
import solicitudes from './admin/solicitudes';
export default combineReducers({
  //  Autenticacion
      auth,

      //  Admin
      //  Solicitudes
      solicitudes,
});