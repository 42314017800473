import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

function PrivateRoute({ children, roles, user }) {

  const auth = JSON.parse(sessionStorage.getItem("isAuthenticated"));
  const userRole = sessionStorage.getItem('role'); // Usar el rol almacenado en sessionStorage

  if (!auth) {
    Swal.fire("No hay sesión activa", "Por favor, inicia sesión", "warning");
    return <Navigate to="/" />;
  }

  if (roles && roles.length > 0 && !roles.includes(userRole)) {
    console.log("No tienes permiso para acceder a esta página");
    Swal.fire("Acceso denegado", "No tienes permiso para acceder a esta página", "error");
    return <Navigate to="/inicio" />;
  }

  return children;
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(PrivateRoute);
