import { IconMenu2 } from "@tabler/icons-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="container sticky top-0 z-sticky">
      <div className="flex flex-wrap">
        <div className="w-full max-w-full px-3 flex-0">
          <nav className="absolute top-0 left-0 right-0 z-30 flex flex-wrap items-center px-4 py-2 mx-6 my-4 shadow-soft-2xl rounded-blur bg-white/80 backdrop-blur-2xl backdrop-saturate-200 lg:flex-nowrap lg:justify-start">
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex justify-between items-center">
                <Link
                  className="text-sm mr-4 ml-4 whitespace-nowrap font-poppins-bold text-slate-700 lg:ml-0 flex items-center gap-x-3"
                  to="/"
                >
                  <img
                    className="h-10"
                    src="/logo.png"
                    alt="Watti Logo"
                  />
                  <span className="hidden lg:block text-lg">Watti Energy</span>
                </Link>
                <button
                  onClick={toggleMenu}
                  className="px-3 py-1 ml-2 leading-none transition-all bg-transparent border border-transparent border-solid rounded-lg shadow-none cursor-pointer text-lg ease-soft-in-out lg:hidden"
                  type="button"
                  aria-controls="navigation"
                  aria-expanded={menuOpen ? "true" : "false"}
                  aria-label="Toggle navigation"
                >
                  <IconMenu2 />
                </button>
              </div>
              <div
                navbar-menu="true"
                className={`items-center justify-end flex-grow overflow-hidden transition-all duration-500 ease-soft lg:flex lg:basis-auto ${
                  menuOpen ? "max-h-96 mt-5" : "max-h-0 lg:max-h-full"
                }`}
              >
                <ul className="flex flex-col lg:flex-row">
                  <li>
                    <Link
                      className="block px-4 py-2 mr-2 font-normal transition-all duration-250 ease-soft-in-out text-sm text-slate-700 lg:px-2"
                      to="/"
                      onClick={toggleMenu}
                    >
                      <i className="mr-1 fas fa-key opacity-60"></i>
                      Iniciar sesión
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-2 mr-2 font-normal transition-all duration-250 ease-soft-in-out text-sm text-slate-700 lg:px-2"
                      to="/register"
                      onClick={toggleMenu}
                    >
                      <i className="mr-1 fas fa-user-circle opacity-60"></i>
                      Regístrate
                    </Link>
                  </li>



                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
