import { IconCopy, IconUserPlus, IconLink } from "@tabler/icons-react";
function ComoFunciona (){
  const handleCopyLink = () => {
    // Aquí va la lógica para copiar el enlace
    alert("Enlace copiado al portapapeles");
  };

  const handleInviteFriends = () => {
    // Aquí va la lógica para invitar a amigos
    alert("Invitación enviada");
  };
  return(
    <div className="flex flex-col pt-2 rounded-xl border-neutral-500 border-opacity-50 shadow-md mt-8 bg-white dark:bg-[#333844] p-4">
    <div className="flex flex-wrap gap-10 justify-center items-center w-full max-md:max-w-full">
      <div className="flex flex-col grow shrink justify-center self-stretch my-auto min-h-[222px] min-w-[240px] w-[398px] max-md:max-w-full">
        <div className="flex max-w-full w-[482px]">
          <div className="flex flex-col justify-center px-1.5 min-w-[240px] w-[438px]">
            <div className="text-2xl font-poppins-semibold tracking-tight leading-9 text-slate-800 dark:text-white max-md:max-w-full">
              Recomienda a amigos y disfruta de los beneficios y recompensas
            </div>
            <div className="mt-1.5 text-sm text-slate-400 dark:text-gray-300 max-md:max-w-full">
              Gana recompensas por la energía generada de tus referidos
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col grow shrink self-stretch my-auto min-w-[240px] w-[339px] max-md:max-w-full">
        <div className="self-start text-2xl font-poppins-semibold tracking-tight leading-none text-center text-slate-800 dark:text-white max-md:max-w-full">
          ¿Cómo funciona?
        </div>
        <div className="flex flex-col mt-2.5 w-full max-md:max-w-full">
          <div className="flex gap-1.5 justify-center items-start w-full max-md:max-w-full">
            <div className="px-2 text-xl font-poppins-bold text-center text-white bg-sky-500 rounded-2xl h-[27px] min-h-[27px] w-[27px]">
              1
            </div>
            <div className="text-sm leading-5 text-justify text-slate-400 dark:text-gray-300 w-[355px]">
              Comparte tu enlace de recomendación con tus amigos
            </div>
          </div>
          <div className="flex gap-1.5 justify-center items-start mt-6 w-full max-md:max-w-full">
            <div className="px-2 text-xl font-poppins-bold text-center text-white bg-sky-500 rounded-2xl h-[27px] min-h-[27px] w-[27px]">
              2
            </div>
            <div className="text-sm leading-5 text-justify text-slate-400 dark:text-gray-300 w-[355px]">
              Invita a amigos a que se registren, soliciten el servicio SolarFlex o compren su planta de energía solar.
            </div>
          </div>
          <div className="flex gap-1.5 justify-center items-start mt-6 w-full max-md:max-w-full">
            <div className="px-2 text-xl font-poppins-bold text-center text-white bg-sky-500 rounded-2xl h-[27px] min-h-[27px] w-[27px]">
              3
            </div>
            <div className="text-sm leading-5 text-justify text-slate-400 dark:text-gray-300 w-[355px]">
              Acomula la energía que generen las plantas de tus amigos para llenar tus baterías digitales.
            </div>
          </div>
          <div className="flex gap-1.5 justify-center items-start mt-6 w-full max-md:max-w-full">
            <div className="px-2 text-xl font-poppins-bold text-center text-white bg-sky-500 rounded-2xl h-[27px] min-h-[27px] w-[27px]">
              4
            </div>
            <div className="text-sm leading-5 text-justify text-slate-400 dark:text-gray-300 w-[355px]">
              Recibe recompensas canjeando tus baterías digitales.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex gap-1.5 justify-center items-center mt-6 w-full max-md:max-w-full">
      <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[999px] max-md:max-w-full">
        <div className="flex gap-10 items-start max-w-full w-[999px]">
          <div className="flex overflow-hidden flex-col flex-1 shrink py-2.5 pr-2.5 pl-2.5 w-full rounded-lg border-solid basis-0 bg-[radial-gradient(72.87%_114.83%_at_17.58%_4.3%,rgba(46,167,255,0.25)_0%,rgba(28,27,51,0.10)_78.06%)] border-[0.48px] border-sky-500 border-opacity-10 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col self-center max-w-full w-[187px]">
              <div className="flex shrink-0 h-px rounded-md"></div>
            </div>
            <div className="flex overflow-hidden flex-col justify-center p-2.5 mt-1.5 w-full rounded-md max-md:max-w-full">
              <div className="self-start text-sm font-poppins-medium text-center text-neutral-600 dark:text-gray-300">
                Enlace de referidos
              </div>
              <div className="flex flex-wrap gap-5 justify-center items-center mt-4 w-full max-w-[960px] max-md:max-w-full">
                <div className="flex overflow-hidden flex-wrap grow shrink items-center self-stretch px-3.5 my-auto text-sm whitespace-nowrap bg-white dark:bg-[#333844] rounded-lg border-solid shadow-sm border-[0.477px] border-zinc-200 dark:border-gray-700 min-h-[29px] min-w-[240px] text-neutral-500 w-[715px] max-md:max-w-full">
                  <div className="flex overflow-hidden flex-col flex-1 shrink justify-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                    <div className="text-neutral-600 dark:text-gray-300">https://acount/referidos/watti......</div>
                    <div className="flex self-start min-h-0"></div>
                  </div>
                  <IconLink size={15} className="text-gray-500 dark:text-gray-300" />
                </div>
                <div 
                  onClick={handleCopyLink}
                  className="flex justify-center items-center grow shrink gap-1 self-stretch px-6 my-auto text-xs font-poppins-bold leading-none text-center uppercase rounded-md min-h-[29px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] bg-white dark:bg-[#333844] text-slate-800 dark:text-gray-300 w-[148px] max-md:px-5 cursor-pointer"
                >
                  COPIAR ENLACE
                </div>
              </div>
              <div className="flex flex-col pb-2 mt-4 w-full text-lg font-poppins-semibold text-center text-white rounded-none max-md:max-w-full">
                <div 
                  onClick={handleInviteFriends}
                  className="flex flex-col-2 justify-center px-3.5 py-3 w-full bg-sky-500 rounded-xl min-h-[37px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:max-w-full cursor-pointer"
                >
                  <div className="flex-1 shrink gap-1 w-full max-md:max-w-full">
                    Invitar a amigos
                  </div>
                <IconUserPlus size={19} className="text-white mt-2 mx-auto" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ComoFunciona;