import {
  SOLICITUDES_SUCCESS,
  SOLICITUDES_FAIL,
  SOLICITUD_SUCCESS,
  SOLICITUD_FAIL
} from "../solicitudes/types"
import axios from "axios"


export const getSolicitudes = () => async dispatch => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/solicitud`);

    dispatch({
      type: SOLICITUDES_SUCCESS,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: SOLICITUDES_FAIL
    });
  }
}


export const getSolicitud = (id) => async dispatch => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/solicitud/${id}`);

    dispatch({
      type: SOLICITUD_SUCCESS,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: SOLICITUD_FAIL
    });
  }
}
