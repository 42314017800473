import React, { useEffect, useState } from "react";
import axios from "axios";
import bg from "../../assets/img/bg.svg";
import NavBar from "../../components/auth/NavBar";
import Footer from "../../components/auth/Footer";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor ingresa tu correo electrónico",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/recuperar-clave`,
        { email }
      );
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Correo enviado",
          text: "Por favor, revisa tu bandeja de entrada para las instrucciones de recuperación de la contraseña.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al intentar enviar el correo de recuperación.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al intentar enviar el correo de recuperación.",
      });
      console.error("Error al enviar la solicitud:", error);
    }
  };

  return (
    <div className="m-0 font-sans antialiased font-normal bg-white text-start text-base leading-default text-slate-500">
      <NavBar />
      <main className="mt-0 transition-all duration-200 ease-soft-in-out">
        <section>
          <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="container z-10">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 mt-32 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                      <h3 className="relative z-10 font-poppins-bold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text " style={{ fontSize: "32px" }}>
                        ¿Olvidaste tu contraseña?
                      </h3>
                      <p className="mb-0">
                        No te preocupes, te ayudaremos a restablecerla. por favor completa los datos
                      </p>
                    </div>
                    <div className="flex-auto p-6">
                      <form role="form" onSubmit={handleSubmit}>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Correo electrónico
                        </label>
                        <div className="mb-4">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                            placeholder="Correo electrónico"
                            aria-label="Correo electrónico"
                            aria-describedby="email-addon"
                          />
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="inline-block w-full px-6 py-3 mt-6 mb-0 font-poppins-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-blue-600 to-cyan-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
                          >
                            Recuperar Contraseña
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-full px-3 lg:flex-0 shrink-0 md:w-6/12">
                  <div className="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                    <div
                      className="absolute inset-x-0 top-0 z-0 h-full -ml-16 bg-cover skew-x-10"
                      style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
