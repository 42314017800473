import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { getSolicitud } from "../../redux/actions/admin/solicitudes/solicitudes";
import { connect } from "react-redux";
import { IconClipboardText, IconEdit, IconPlus } from "@tabler/icons-react";

const InputField = ({ label, value, name, onChange }) => (
  <div className="flex flex-wrap gap-3 items-center w-full my-2">
    <div className="my-auto text-xs font-medium leading-5 text-black dark:text-white w-[150px]">
      {label}
    </div>
    <div className="self-stretch my-auto text-xs font-semibold text-slate-500">
      :
    </div>
    <Link to={"#"}>
      <IconEdit size={18} />
    </Link>
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      className="flex-1 shrink my-auto bg-transparent border border-gray-300 dark:border-zinc-600 outline-none text-black dark:text-gray-300 rounded-md p-1"
    />
  </div>
);

const DocumentField = ({ label, name, onChange }) => {
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onChange(name, file);
    setFileUploaded(!!file);
  };

  return (
    <div className="flex flex-wrap gap-3 items-center w-full my-2">
      <div className="my-auto text-xs font-medium leading-5 text-black dark:text-white w-[150px]">
        {label}
      </div>
      <div className="self-stretch my-auto text-xs font-semibold text-slate-500">
        :
      </div>
      <Link to={"#"}>
        <IconEdit size={18} />
      </Link>
      <div className="flex items-center gap-2.5 flex-1 shrink self-stretch p-1 my-auto rounded-md border-neutral-600 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
        <IconClipboardText size={18} />
        <input
          type="file"
          name={name}
          className="text-xs font-medium leading-3 text-black dark:text-gray-300 w-full bg-transparent border border-gray-300 dark:border-zinc-600 rounded-md p-1"
          onChange={handleFileChange}
        />
      </div>
      {fileUploaded && (
        <div className="text-xs leading-none text-lime-500 mt-1">
          Subida con éxito
        </div>
      )}
    </div>
  );
};

function AdminDetalleSolicitud({ getSolicitud, solicitud }) {
  const params = useParams();
  const uid = params.uid;

  const [formData, setFormData] = useState({
    nombre_solicitante: "",
    nit: "",
    ciudad: "",
    direccion: "",
    telefono: "",
    email: "",
    persona_contacto: "",
    estrato: "",
    tipo_cliente: "",
    estado: "",
    recibo: null,
    propuesta_comercial: null,
    contrato: null,
    informe_mantenimiento: null,
    fotos_visita: null,
    informe_visita: null,
    propuesta_epc: null,
    etapas_instalacion: "",
  });

  useEffect(() => {
    getSolicitud(uid);
  }, [getSolicitud, uid]);

  useEffect(() => {
    if (solicitud) {
      setFormData({
        nombre_solicitante: solicitud.nombre_solicitante || "",
        nit: solicitud.nit || "",
        ciudad: solicitud.ciudad || "",
        direccion: solicitud.direccion || "",
        telefono: solicitud.telefono || "",
        email: solicitud.email || "",
        persona_contacto: solicitud.persona_contacto || "",
        estrato: solicitud.estrato || "",
        tipo_cliente: solicitud.tipo_cliente || "",
        estado: solicitud.estado || "",
        recibo: solicitud.recibo || null,
        propuesta_comercial: solicitud.propuesta_comercial || null,
        contrato: solicitud.contrato || null,
        informe_mantenimiento: solicitud.informe_mantenimiento || null,
        fotos_visita: solicitud.fotos_visita || null,
        informe_visita: solicitud.informe_visita || null,
        propuesta_epc: solicitud.propuesta_epc || null,
        etapas_instalacion: solicitud.etapas_instalacion || "",
      });
    }
  }, [solicitud]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (name, file) => {
    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Aquí podrías enviar `formData` a tu backend.
  };

  return (
    <DashboardLayout>
      <div className="relative flex flex-col px-4 py-4 w-full max-w-full">
        <div className="relative z-10 flex flex-col rounded-2xl h-auto w-full bg-gray-200 dark:bg-gray-800 border border-solid border-gray-300 dark:border-zinc-600 p-4 shadow-md">
          <div className="text-lg leading-snug text-gray-900 dark:text-white mb-2">
            Progreso de la Solicitud
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="text-xs font-medium text-gray-600 dark:text-slate-400">
              Progreso de la solicitud
            </div>
            <div className="text-sm font-bold text-gray-900 dark:text-white">
              80%
            </div>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-2.5 dark:bg-gray-700 mb-4">
            <div
              className="bg-blue-500 h-2.5 rounded-full"
              style={{ width: "80%" }}
            ></div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-4">
            <div className="flex-1 text-sm text-gray-700 dark:text-slate-500">
              <span>More than</span>
              <span className="text-gray-900 dark:text-slate-500">
                {" "}
                1,500,000 developers
              </span>
              <span> used Creative Tim's products and over</span>
              <span className="text-gray-900 dark:text-slate-500">
                {" "}
                700,000 projects
              </span>
              <span> were created.</span>
            </div>
            <div className="flex justify-center items-center text-xs font-semibold text-center text-white dark:text-gray-900 bg-blue-500 rounded-lg px-4 py-2 cursor-pointer sm:min-w-[135px] shadow-md">
              VER CLIENTE
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-10 justify-between items-center px-8 py-2.5 max-md:px-5">
        <div className="self-stretch my-auto text-lg font-semibold tracking-tighter leading-none text-gray-900 dark:text-white">
          Detalles de solicitud
        </div>
        <div className="flex grow shrink gap-2.5 justify-center items-center  px-1 py-2 my-auto text-base font-medium leading-none text-sky-500 dark:text-blue-500 rounded-lg border border-sky-500 dark:border-blue-500 border-solid min-h-[31px] min-w-[240px] shadow-md w-[221px] cursor-pointer">
          <IconPlus size={14} />
          <div className="self-stretch my-auto">Nueva Solicitud SolarFlex</div>
        </div>
      </div>
      <div className="flex flex-col px-8 pt-2 pb-9 rounded-2xl border border-solid bg-gray-50 dark:bg-gray-800 dark:border-zinc-600 max-md:px-5">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="gap-2.5 px-px py-2.5 max-w-full text-lg font-semibold tracking-tighter leading-none text-black dark:text-white w-[175px]">
            Solicitud #1
          </div>
          <div className="flex flex-col mt-2 w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-9 w-full max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink self-start rounded-2xl basis-0 min-w-[240px] shadow-[0px_0px_30px_rgba(0,0,0,0.3)] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-semibold leading-snug text-black dark:text-white rounded-none max-md:max-w-full mt-2">
                  <div className="gap-2 self-stretch px-2.5 py-1.5 rounded-md">
                    DATOS DEL CLIENTE
                  </div>
                </div>
                <div className="flex flex-col justify-center p-5 w-full text-xs max-md:max-w-full">
                  <InputField
                    label="Persona de contacto"
                    name="persona_contacto"
                    value={formData.persona_contacto}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Cédula de ciudadanía"
                    name="cedula"
                    value={formData.cedula}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Nombre de la empresa"
                    name="nombre_solicitante"
                    value={formData.nombre_solicitante}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Nit"
                    name="nit"
                    value={formData.nit}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Ciudad"
                    name="ciudad"
                    value={formData.ciudad}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Dirección"
                    name="direccion"
                    value={formData.direccion}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Barrio"
                    name="barrio"
                    value={formData.barrio}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Estrato"
                    name="estrato"
                    value={formData.estrato}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Teléfono"
                    name="telefono"
                    value={formData.telefono}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Correo electrónico"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <InputField
                    label="Tipo de cliente"
                    name="tipo_cliente"
                    value={formData.tipo_cliente}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink rounded-2xl basis-0 min-w-[240px] shadow-[0px_0px_30px_rgba(0,0,0,0.3)] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-semibold leading-snug text-black dark:text-white rounded-none max-md:max-w-full mt-2">
                  <div className="gap-2 self-stretch px-2.5 py-1.5 rounded-md">
                    DOCUMENTOS
                  </div>
                </div>
                <div className="flex flex-col justify-center p-5 w-full max-md:max-w-full">
                  <DocumentField
                    label="Recibo de Energía"
                    name="recibo"
                    onChange={handleFileChange}
                  />
                  <DocumentField
                    label="Propuesta Comercial"
                    name="propuesta_comercial"
                    onChange={handleFileChange}
                  />
                  <DocumentField
                    label="Contrato"
                    name="contrato"
                    onChange={handleFileChange}
                  />
                  <DocumentField
                    label="Informe visita técnica"
                    name="informe_visita"
                    onChange={handleFileChange}
                  />
                  <DocumentField
                    label="Fotos visita técnica"
                    name="fotos_visita"
                    onChange={handleFileChange}
                  />
                  <DocumentField
                    label="Cotización EPC"
                    name="propuesta_epc"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-9 items-start w-full max-md:max-w-full mt-4">
              <div className="flex flex-col flex-1 shrink rounded-2xl basis-0 min-w-[240px] shadow-[0px_0px_30px_rgba(0,0,0,0.3)] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-semibold leading-snug text-black dark:text-white rounded-none max-md:max-w-full mt-2">
                  <div className="gap-2 self-stretch px-2.5 py-1.5 rounded-md">
                    PROCESO DE LA SOLICITUD
                  </div>
                </div>
                <div className="flex flex-col justify-center p-5 w-full text-base min-h-[58px] max-md:max-w-full">
                  <div className="flex gap-3 items-center w-full">
                    <div className="flex-1 shrink self-stretch my-auto font-medium text-black dark:text-white">
                      Estado de la Solicitud
                    </div>
                    <div className="self-stretch my-auto font-semibold text-slate-500">
                      :
                    </div>
                    <Link to={"#"}>
                      <IconEdit size={18} />
                    </Link>
                    <select
                      name="estado"
                      value={formData.estado}
                      onChange={handleChange}
                      className="flex-1 shrink my-auto bg-transparent border border-gray-300 dark:border-zinc-600 outline-none text-black dark:text-gray-300 rounded-md p-1"
                    >
                      <option value="solicitud">Solicitud</option>
                      <option value="aprobada">Aprobada</option>
                      <option value="rechazada">Rechazada</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink rounded-2xl basis-0 min-w-[240px] shadow-[0px_0px_30px_rgba(0,0,0,0.3)] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-semibold leading-snug text-black dark:text-white rounded-none max-md:max-w-full mt-2">
                  <div className="gap-2 self-stretch px-2.5 py-1.5 rounded-md">
                    PROCESO DE INSTALACIÓN
                  </div>
                </div>
                <div className="flex flex-col justify-center p-5 w-full text-base min-h-[58px] max-md:max-w-full">
                  <div className="flex gap-3 items-center w-full">
                    <div className="flex-1 shrink self-stretch my-auto font-medium text-black dark:text-white">
                      Etapas de Instalación
                    </div>
                    <div className="self-stretch my-auto font-semibold text-slate-500">
                      :
                    </div>
                    <Link to={"#"}>
                      <IconEdit size={18} />
                    </Link>
                    <select
                      name="etapas_instalacion"
                      value={formData.etapas_instalacion}
                      onChange={handleChange}
                      className="flex-1 shrink my-auto bg-transparent border border-gray-300 dark:border-zinc-600 outline-none text-black dark:text-gray-300 rounded-md p-1"
                    >
                      <option value="instalacion_medidor">
                        Instalación de medidor
                      </option>
                      <option value="construccion">Construcción</option>
                      <option value="puesta_en_servicio">
                        Puesta en servicio
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 mt-9 w-full text-base font-semibold text-center text-gray-200 max-w-[1053px] max-md:max-w-full">
          <div className="gap-4 self-stretch px-2.5 py-2 bg-gray-600 rounded-lg border-t border-l border-gray-100 min-h-[34px] w-[214px]">
            Ver cliente
          </div>
          <div
            className="gap-4 self-stretch px-2.5 py-2 rounded-lg border-t border-l border-gray-100 min-h-[34px] w-[214px] cursor-pointer"
            onClick={handleSubmit}
          >
            Guardar cambios
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  solicitud: state.solicitudes.solicitud,
});

export default connect(mapStateToProps, { getSolicitud })(
  AdminDetalleSolicitud
);
