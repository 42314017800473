import DashboardLayout from "../../hocs/layout/DashboardLayout";
import Perfil from "../../components/perfil/Profile";
import Calendar from "../../components/Calendar";

function AdminDashboard() {
  return (
    <DashboardLayout>
      <Perfil perfil={false} />
      <div className="flex flex-col p-2.5 rounded-2xl">
        <div className="flex flex-wrap gap-5 items-start w-full text-lg font-semibold leading-snug text-center dark:text-white text-gray-900 whitespace-nowrap max-md:max-w-full">
          <div className="flex flex-1 shrink gap-2.5 justify-center items-center rounded-2xl border-b-2 border-sky-500 basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex-1 shrink gap-2.5 self-stretch py-1.5 my-auto w-full min-w-[240px] max-md:max-w-full">
              Clientes
            </div>
          </div>
          <div className="flex flex-1 shrink gap-2.5 justify-center items-center rounded-2xl border-b-2 border-sky-500 basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex-1 shrink gap-2.5 self-stretch py-1.5 my-auto w-full min-w-[240px] max-md:max-w-full">
              Solicitudes
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-5 justify-center items-start mt-1.5 w-full max-md:max-w-full">
          <div className="flex flex-wrap flex-1 shrink gap-2.5 justify-center items-center basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-w-[240px]">
              <div className="flex flex-col justify-center items-center px-3 py-3.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Clientes Registrados
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center px-3 py-3.5 mt-2.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Clientes SolarFlex
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-w-[240px]">
              <div className="flex flex-col justify-center items-center px-3 py-3.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Clientes Sin Servicio
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center px-3 py-3.5 mt-2.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Clientes 5+5
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap flex-1 shrink gap-2.5 justify-center items-center basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-w-[240px]">
              <div className="flex flex-col justify-center items-center px-3 py-3.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Total Solicitudes
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center px-3 py-3.5 mt-2.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-px justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Solicitudes Desaprobadas
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 w-7 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-w-[240px]">
              <div className="flex flex-col justify-center items-center px-3 py-3.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-7 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Solicitudes Aprobadas
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)] w-[27px]"></div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center px-3 py-3.5 mt-2.5 w-full bg-gray-200 dark:bg-gray-800 rounded-lg border border-solid border-zinc-300 dark:border-zinc-600 min-h-[81px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)]">
                <div className="flex gap-10 justify-between items-start max-w-full w-[213px]">
                  <div className="flex flex-col">
                    <div className="text-sm text-gray-600 dark:text-slate-400">
                      Total Solicitudes Mes
                    </div>
                    <div className="flex gap-2.5 items-center self-start mt-1 whitespace-nowrap">
                      <div className="flex gap-2.5 items-end self-stretch my-auto">
                        <div className="text-4xl font-semibold leading-none text-gray-900 dark:text-white">
                          12
                        </div>
                        <div className="text-xs font-medium leading-snug text-green-500">
                          +55%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2.5 justify-center items-center px-1 h-7 bg-sky-500 rounded-md min-h-[28px] shadow-[0px_2px_3px_rgba(0,0,0,0.02)] w-[27px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center rounded-xl mt-4 gap-2">
          <Calendar />
          <div className="px-3 pt-3 w-full pb-10 rounded-xl bg-gray-200 dark:bg-gray-800 mt-4">
            <div className="self-center text-xl font-semibold dark:text-white text-gray-900">
              Progreso de Solicitudes
            </div>
            <div className="flex flex-col mt-4 w-full">
              <div className="w-full text-xs font-bold leading-snug rounded-none dark:text-slate-400 text-gray-600">
                Solicitudes en Progreso
              </div>
              <div className="flex flex-col mt-2 w-full">
                <div className="flex gap-4 items-center py-3 w-full border-t border-b dark:border-white border-gray-300 border-opacity-10">
                  <div className="flex flex-1 shrink gap-2 self-stretch my-auto basis-6 min-w-[240px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/43ac51a2e2834cc0fd3ebaa265eff36c60197726ee598eddcef59ba25eeacdd7?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                      className="my-auto rounded-none aspect-square w-[52px]"
                    />
                    <div className="flex flex-col flex-1 shrink justify-center basis-0">
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col max-w-full leading-snug w-[188px]">
                          <div className="flex gap-1 items-center self-start">
                            <div className="self-stretch my-auto text-xs font-medium dark:text-white text-gray-900">
                              Cliente:
                            </div>
                            <div className="self-stretch my-auto text-xs font-light dark:text-slate-300 text-gray-600">
                              Pedro Perez
                            </div>
                          </div>
                          <div className="flex gap-1 items-center w-full text-xs">
                            <div className="self-stretch my-auto font-medium dark:text-white text-gray-900">
                              ID Cliente:
                            </div>
                            <div className="self-stretch my-auto font-light dark:text-slate-300 text-gray-600">
                              25FG54-86DU6H57T
                            </div>
                          </div>
                          <div className="flex gap-1 items-center w-full text-xs">
                            <div className="self-stretch my-auto font-medium dark:text-white text-gray-900">
                              Estado:
                            </div>
                            <div className="self-stretch my-auto font-light text-orange-500 dark:text-orange-300">
                              Firma del Contrato
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex flex-col w-full">
                            <div className="flex flex-col w-full">
                              <div className="flex gap-10 justify-between items-center w-full leading-snug">
                                <div className="self-stretch my-auto text-xs font-medium dark:text-white text-gray-900">
                                  Progreso de la solicitud:
                                </div>
                                <div className="self-stretch my-auto text-xs text-right dark:text-slate-400 text-gray-600">
                                  80%
                                </div>
                              </div>
                              <div className="flex flex-col mt-1.5 w-full">
                                {/* <div className="w-full border-solid bg-slate-300 dark:bg-slate-700 border-[3px] min-h-[3px] rounded-full">
                                  <div className="max-w-full border-sky-500 border-solid border-[5px] min-h-[5px] w-[174px] rounded-full"></div>
                                </div> */}
                                <progress className="progress progress-info w-auto" value="80" max="100"></progress>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-1 justify-center items-center self-stretch px-3 py-2 my-auto text-xs font-medium leading-none text-center text-white whitespace-nowrap bg-sky-500 rounded-md">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ec0d5ad0c44789c7bf82ab32b959048f1a69a2c26c06d58d8a21b03f2a71905?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                      className="object-contain shrink-0 self-stretch my-auto w-3 aspect-square"
                    />
                    <div className="self-stretch my-auto">Detalles</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-4 w-full">
              <div className="w-full text-xs font-bold leading-snug rounded-none dark:text-slate-400 text-gray-600">
                Solicitudes pendientes
              </div>
              <div className="flex flex-col mt-2 w-full">
                <div className="flex gap-4 items-center py-2 w-full border-t border-b dark:border-white border-gray-300 border-opacity-10">
                  <div className="flex flex-1 shrink gap-2 self-stretch my-auto basis-6 min-w-[240px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3615334b357c7278a8ed4e788aee452351bbea5feb7c243571312d98d0258f03?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                      className="object-contain shrink-0 my-auto rounded-none aspect-square w-[52px]"
                    />
                    <div className="flex flex-col flex-1 shrink justify-center basis-0">
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col max-w-full text-xs leading-snug w-[188px]">
                          <div className="flex gap-1 items-start self-start">
                            <div className="font-medium dark:text-white text-gray-900">
                              Cliente:
                            </div>
                            <div className="font-light dark:text-slate-300 text-gray-600">
                              Juana Suarez
                            </div>
                          </div>
                          <div className="flex gap-1 self-start text-xs font-bold leading-none text-center dark:text-slate-400 text-gray-600">
                            <span className="font-medium dark:text-white text-gray-900">
                              ID Cliente:
                            </span>
                            <span className="font-light dark:text-slate-300 text-gray-600">
                              25FG54-86DU6H57T
                            </span>
                          </div>
                          <div className="flex gap-1 items-center w-full whitespace-nowrap">
                            <div className="self-stretch my-auto font-medium dark:text-white text-gray-900">
                              Estado:
                            </div>
                            <div className="self-stretch my-auto font-light text-orange-500 dark:text-orange-300">
                              Solicitud
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex flex-col w-full">
                            <div className="flex flex-col w-full">
                              <div className="flex gap-10 justify-between items-center w-full leading-snug">
                                <div className="self-stretch my-auto text-xs font-medium dark:text-white text-gray-900">
                                  Progreso de la solicitud:
                                </div>
                                <div className="self-stretch my-auto text-xs text-right dark:text-slate-400 text-gray-600">
                                  0%
                                </div>
                              </div>
                              <div className="flex flex-col mt-1.5 w-full">
                                <progress className="progress progress-info w-auto" value="0" max="100"></progress>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-1 justify-center items-center self-stretch px-3 py-2 my-auto text-xs font-medium leading-none text-center text-white whitespace-nowrap bg-sky-500 rounded-md">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ec0d5ad0c44789c7bf82ab32b959048f1a69a2c26c06d58d8a21b03f2a71905?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                      className="object-contain shrink-0 self-stretch my-auto w-3 aspect-square"
                    />
                    <div className="self-stretch my-auto">Detalles</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AdminDashboard;
