import {
  IconMail,
  IconShieldLock,
  IconKey,
  IconBrandTwitter,
  IconBrandInstagram,
  IconBrandFacebook,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
function Informacion(){
  const user = useSelector((state) => state.auth.user);
  return(
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-5">
    <div className="flex flex-col px-5 py-6 bg-white dark:bg-gray-700 rounded-xl min-h-[266px] w-full shadow">
      <div className="text-base font-poppins-medium leading-snug text-black dark:text-white">
        Profile Information
      </div>
      <div className="mt-3 text-sm text-gray-600 dark:text-gray-400">
        Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer
        is no. If two equally difficult paths, choose the one more painful
        in the short term (pain avoidance is creating an illusion of
        equality).
      </div>
      <div className="mt-3 text-sm">
        <div className="font-poppins-semibold">Nombre Completo:</div>
        <div className="text-gray-700 dark:text-gray-400">
          {user && user.fullName}
        </div>
      </div>
      <div className="mt-3 text-sm">
        <div className="font-poppins-semibold">Telefono:</div>
        <div className="text-gray-700 dark:text-gray-400">
          (44) 123 1234 123
        </div>
      </div>
      <div className="mt-3 text-sm">
        <div className="font-poppins-semibold">Email:</div>
        <div className="text-gray-700 dark:text-gray-400">
          {user && user.email}
        </div>
      </div>
      <div className="mt-3 text-sm">
        <div className="font-poppins-semibold">Locaizacion:</div>
        <div className="text-gray-700 dark:text-gray-400">
          United States
        </div>
      </div>
      <button className="mt-4 px-3 py-2 text-blue-500 dark:text-blue-400 font-poppins-semibold border border-blue-500 dark:border-blue-400 rounded-lg">
        Editar Información
      </button>
    </div>

    <div className="flex flex-col px-5 py-6 bg-white dark:bg-gray-700 rounded-xl min-h-[266px] w-full shadow">
      <div className="text-base font-poppins-medium leading-snug text-black dark:text-white">
        Profile Information
      </div>
      <div className="flex items-center gap-3 p-3 mt-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
        <IconMail
          size={24}
          className="text-gray-700 dark:text-gray-300"
        />
        <div className="text-base font-poppins-semibold text-gray-600 dark:text-gray-300">
          Correo electronico
        </div>
        <span className="ml-auto px-2 py-1 text-xs font-poppins-semibold bg-emerald-100 dark:bg-emerald-700 text-emerald-600 dark:text-emerald-200 rounded">
          Verificado
        </span>
      </div>
      <div className="flex items-center gap-3 p-3 mt-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
        <IconShieldLock
          size={24}
          className="text-gray-700 dark:text-gray-300"
        />
        <div className="text-base font-poppins-semibold text-gray-600 dark:text-gray-300">
          Autenticación de dos factores (2FA)
        </div>
      </div>
      <div className="flex items-center gap-3 p-3 mt-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
        <IconKey size={24} className="text-gray-700 dark:text-gray-300" />
        <div className="text-base font-poppins-semibold text-gray-600 dark:text-gray-300">
          Contraseña
        </div>
      </div>
    </div>

    <div className="flex flex-col px-5 py-6 bg-white dark:bg-gray-700 rounded-xl min-h-[266px] w-full shadow">
      <div className="text-base font-poppins-medium leading-snug text-black dark:text-white">
        Profile Information
      </div>
      <div className="mt-3 text-sm text-gray-600 dark:text-gray-400">
        Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer
        is no. If two equally difficult paths, choose the one more painful
        in the short term (pain avoidance is creating an illusion of
        equality).
      </div>
      <div className="mt-4 flex items-center gap-3">
        <IconBrandFacebook
          size={24}
          className="text-gray-700 dark:text-gray-300"
        />
        <IconBrandTwitter
          size={24}
          className="text-gray-700 dark:text-gray-300"
        />
        <IconBrandInstagram
          size={24}
          className="text-gray-700 dark:text-gray-300"
        />
      </div>
    </div>
  </div>
  )
}

export default Informacion;