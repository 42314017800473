import React, { useState } from 'react';
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import Profile from "../../components/perfil/Profile";
import { Link } from 'react-router-dom';

function Facturas() {
  const [selectedTab, setSelectedTab] = useState('factura');

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col p-6 bg-white dark:bg-[#282C34] rounded-2xl">
        <Profile perfil={false} />

        <div className="flex flex-col px-2 py-5 bg-white dark:bg-[#333844] rounded-2xl mt-4">
          <div className="flex flex-col self-center py-0.5 max-w-full text-2xl text-center rounded-xl w-[431px]">
            <div className="flex">
              <div
                onClick={() => setSelectedTab('factura')}
                className={`cursor-pointer px-4 py-2 rounded-l-xl ${
                  selectedTab === 'factura'
                    ? 'bg-blue-500 text-white'
                    : 'bg-white dark:bg-[#333844] text-gray-700 dark:text-gray-300'
                } shadow-md`}
              >
                Mi Factura
              </div>
              <div
                onClick={() => setSelectedTab('historial')}
                className={`cursor-pointer px-4 py-2 rounded-r-xl ${
                  selectedTab === 'historial'
                    ? 'bg-blue-500 text-white'
                    : 'bg-white dark:bg-[#333844] text-gray-700 dark:text-gray-300'
                } shadow-md`}
              >
                Historial de pagos
              </div>
            </div>
          </div>

          {selectedTab === 'factura' ? (
            <Link to={`/factura/detalle/${1}`} >
            <div className="flex flex-wrap gap-10 justify-between items-center px-2 py-3 mt-3 w-full text-gray-400 dark:text-gray-300 rounded-md border border-solid bg-slate-50 dark:bg-[#282C34] border-zinc-200 dark:border-zinc-700 max-md:max-w-full">
              
              <div className="flex gap-2 items-center self-stretch my-auto">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3040a5fbe53062a1f23ba9512beb3d1da22e2fc96a30cfa2fab0ea0ac1a64769?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                  className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square"
                />
              
                <div className="flex gap-2 items-center self-stretch my-auto w-[139px]">
                  <div className="flex flex-col self-stretch my-auto w-[139px]">
                    <div className="text-sm font-poppins-medium dark:text-gray-200">Factura Marzo 2024</div>
                    <div className="text-xs font-poppins-semibold dark:text-gray-400">600.000COP</div>
                  </div>
                </div>
              </div>
          
            </div>
            </Link>
          ) : (
            <div className="flex justify-center mt-4 text-lg text-gray-500 dark:text-gray-400">
              Historial de pagos - Aquí puedes ver todas tus transacciones pasadas.
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Facturas;
