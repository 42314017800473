import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Error404 from "../../containers/error/Error404";
import Login from "../../containers/auth/Login";
import ForgotPassword from "../../containers/auth/ForgotPassword";
import Dashboard from "../../containers/Dashboard";
import Monitorizacion from "../../containers/Monitoreo";
import Register from "../../containers/auth/Register";
import TokenVerify from "../../containers/auth/Token";
import Profile from "../../containers/Profile";
import SolicitarRenting from "../../containers/solicitud/SolicitudRenting";
import SolicitudVisita from "../../containers/solicitud/SolicitudVisita";
import Facturas from "../../containers/facturas/Facturas";
import FacturaDetalle from "../../containers/facturas/FacturaDetalle";
import Referidos from "../../containers/referidos/Referidos";
import PasswordConfirm from "../../containers/auth/PasswordConfirm";
import PrivateRoutes from "./PrivateRoutes";
import AdminDashboard from "../../containers/admin/AdminDashboard";
import AdminSolicitudes from "../../containers/admin/AdminSolicitudes";
import AdminDetalleSolicitud from "../../containers/admin/AdminDetalleSolicitud";
import AdminPerfil from "../../containers/admin/AdminPerfil";
import Soporte from "../../containers/Soporte";
function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password_reset_confirm/:uid/:token" element={<PasswordConfirm />} />
        <Route path="/register" element={<Register />} />
        <Route path="/token/verify" element={<TokenVerify />} />

        <Route path="/inicio" element={
          <PrivateRoutes roles={["cliente"]}>
            <Dashboard />
          </PrivateRoutes>
        } />
        <Route path="/perfil" element={
          <PrivateRoutes roles={["admin", "cliente", "epc"]}>
            <Profile />
          </PrivateRoutes>
        } />

        {/* Rutas específicas para el rol de cliente */}
        <Route path="/monitoreo/:uid" element={
          <PrivateRoutes roles={["cliente"]}>
            <Monitorizacion />
          </PrivateRoutes>
        } />
        <Route path="/solicitar-renting" element={
          <PrivateRoutes roles={["cliente"]}>
            <SolicitarRenting />
          </PrivateRoutes>
        } />
        <Route path="/solicitar-visita" element={
          <PrivateRoutes roles={["cliente"]}>
            <SolicitudVisita />
          </PrivateRoutes>
        } />
        <Route path="/facturas" element={
          <PrivateRoutes roles={["cliente"]}>
            <Facturas />
          </PrivateRoutes>
        } />
        <Route path="/factura/detalle/:uid" element={
          <PrivateRoutes roles={["cliente"]}>
            <FacturaDetalle />
          </PrivateRoutes>
        } />
        <Route path="/referidos" element={
          <PrivateRoutes roles={["cliente"]}>
            <Referidos />
          </PrivateRoutes>
        } />
        <Route path="/soporte" element={
          <PrivateRoutes roles={["cliente"]}>
            <Soporte />
          </PrivateRoutes>
        } />
        {/* Ejemplo de una ruta específica para el rol "epc" */}
        <Route path="/epc-inicio" element={
          <PrivateRoutes roles={["epc"]}>
            {/* Componente específico para el rol "epc" */}
          </PrivateRoutes>
        } />

        {/* Rutas específicas para el rol de administrador */}
        <Route path="/admin/inicio" element={
          <PrivateRoutes roles={["admin"]}>
            <AdminDashboard />
          </PrivateRoutes>
        } />
        <Route path="/admin/solicitudes" element={
          <PrivateRoutes roles={["admin"]}>
            <AdminSolicitudes />
          </PrivateRoutes>
        } />
        <Route path="/admin/solicitud/detalle/:uid" element={
          <PrivateRoutes roles={["admin"]}>
            <AdminDetalleSolicitud />
          </PrivateRoutes>
        } />
        <Route path="/admin/perfil" element={
          <PrivateRoutes roles={["admin"]}>
            <AdminPerfil />
          </PrivateRoutes>
        } />
        {/* <Route path="/admin/users" element={
          <PrivateRoutes roles={["admin"]}>
            
          </PrivateRoutes>
        } />
        <Route path="/admin/settings" element={
          <PrivateRoutes roles={["admin"]}>
            
          </PrivateRoutes>
        } /> */}

        {/* Agrega más rutas según lo necesites */}
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
