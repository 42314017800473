import React, { useState } from "react";
import Profile from "../../components/perfil/Profile";
import DashboardLayout from "../../hocs/layout/DashboardLayout";

function FacturaDetalle() {
  const handleDownloadPDF = () => {
    alert("Descargando recibo en PDF...");
  };
  const [selectedTab, setSelectedTab] = useState('factura');
  
  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col items-center p-6 bg-white dark:bg-[#282C34] rounded-2xl">

        <Profile perfil={false} />

        <div className="flex flex-col items-center py-10 bg-white dark:bg-[#282C34] rounded-2xl mt-4 w-full max-w-[800px]">
          <div className="flex flex-col self-center py-0.5 max-w-full text-2xl text-center rounded-xl w-[431px]">
            <div className="flex justify-center">
              <div
                onClick={() => setSelectedTab('factura')}
                className={`cursor-pointer px-4 py-2 rounded-l-xl ${
                  selectedTab === 'factura'
                    ? 'bg-blue-500 text-white'
                    : 'bg-white dark:bg-[#333844] text-gray-700 dark:text-gray-300'
                } shadow-md`}
              >
                Mi Factura
              </div>
              <div
                onClick={() => setSelectedTab('historial')}
                className={`cursor-pointer px-4 py-2 rounded-r-xl ${
                  selectedTab === 'historial'
                    ? 'bg-blue-500 text-white'
                    : 'bg-white dark:bg-[#333844] text-gray-700 dark:text-gray-300'
                } shadow-md`}
              >
                Historial de pagos
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-16 max-w-full rounded-none w-full max-md:mt-10">
            <div className="flex relative flex-col w-full min-h-[581px] shadow-[0px_10px_40px_rgba(102,102,102,0.16)] max-md:max-w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c2871ee68e3c4a060f5d27533210e7f37db46960a85d15eaf1ebc287140c24fc?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                className="object-cover absolute inset-0 size-full"
              />
              <div className="flex relative flex-col justify-center px-5 py-12 w-full rounded-2xl min-h-[581px] shadow-[0px_2px_25px_rgba(41,41,41,0.37)] max-md:max-w-full bg-white dark:bg-[#333844]">
                <div className="flex z-0 flex-col items-center self-center max-w-full text-center w-[388px]">
                  <div className="text-2xl font-poppins-semibold leading-snug text-neutral-900 dark:text-gray-200">
                    Pago Realizado con Éxito
                  </div>
                  <div className="mt-2 text-lg leading-loose text-zinc-700 dark:text-gray-400">
                    Su pago se ha realizado con éxito.
                  </div>
                </div>
                <div className="z-0 self-center mt-8 max-w-full border border-gray-200 dark:border-gray-700 border-solid min-h-[1px] w-[368px]"></div>
                <div className="flex z-0 flex-col items-center self-center mt-8 max-w-full text-center w-[368px]">
                  <div className="text-lg leading-loose text-zinc-700 dark:text-gray-400">
                    Pago total:
                  </div>
                  <div className="mt-2 text-3xl font-poppins-semibold leading-none text-neutral-900 dark:text-gray-200">
                    600,000 COP
                  </div>
                </div>
                <div className="flex z-0 flex-col mt-8 w-full">
                  <div className="flex gap-4 items-start w-full">
                    <div className="flex flex-col flex-1 shrink p-4 rounded-lg border border-gray-200 dark:border-gray-700 border-solid basis-0">
                      <div className="text-base leading-none text-neutral-500 dark:text-gray-400">
                        Ref Number
                      </div>
                      <div className="mt-1.5 text-xs font-poppins-medium leading-6 text-neutral-900 dark:text-gray-200">
                        000085752257
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 shrink p-4 rounded-lg border border-gray-200 dark:border-gray-700 border-solid basis-0">
                      <div className="text-sm leading-none text-neutral-500 dark:text-gray-400">
                        Fecha y Hora de Pago:
                      </div>
                      <div className="mt-1.5 text-xs font-poppins-medium leading-6 text-neutral-900 dark:text-gray-200">
                        15
                        <span className="text-xs">de</span> agosto
                        <span className="text-xs">de</span> 2024
                        <span className="text-xs">,</span> 13:22
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 items-start mt-4 w-full">
                    <div className="flex flex-col flex-1 shrink p-4 rounded-lg border border-gray-200 dark:border-gray-700 border-solid basis-0">
                      <div className="text-sm leading-none text-neutral-500 dark:text-gray-400">
                        Método de Pago:
                      </div>
                      <div className="self-start mt-1.5 text-xs font-poppins-medium leading-6 text-center text-neutral-900 dark:text-gray-200">
                        Transferencia
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 shrink p-4 rounded-lg border border-gray-200 dark:border-gray-700 border-solid basis-0">
                      <div className="text-sm leading-none text-neutral-500 dark:text-gray-400">
                        Nombre del remitente
                      </div>
                      <div className="self-start mt-1.5 text-xs font-poppins-medium leading-6 text-center text-neutral-900 dark:text-gray-200">
                        Juan Carlos López
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c158a181d1058b5fe99c598363c7caff886b8786ff4c22d7ebb12c9e5261a96?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                  className="object-contain absolute top-0 left-2/4 z-0 -translate-x-2/4 aspect-[1.97] h-[69px] translate-y-[0%] w-[69px]"
                />
                <div
                  className="flex z-0 gap-2 justify-center items-center px-2 py-3 mt-8 w-full text-base font-poppins-semibold text-center text-white bg-teal-500 rounded-lg border-emerald-200 shadow-[1px_1px_4px_rgba(0,0,0,0.33)] cursor-pointer"
                  onClick={handleDownloadPDF}
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/382f3cd16d1a388704e452822f2796687185a845c1d34fb157e36ac38befec12?placeholderIfAbsent=true&apiKey=0ab98161368b4c16a3033148de97df0a"
                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]"
                  />
                  <div className="flex overflow-hidden flex-col justify-center items-center self-stretch my-auto">
                    <div className="flex items-center min-h-[24px]">
                      <div className="overflow-hidden gap-1 self-stretch my-auto">
                        Obtener recibo en PDF
                      </div>
                    </div>
                    <div className="flex min-h-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </DashboardLayout>
  );
}

export default FacturaDetalle;
