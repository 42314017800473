import React from "react";
import { Link } from "react-router-dom";
import llaves from "../assets/img/dashboard/llaves.svg";
import dolar from "../assets/img/dashboard/dolar.svg";
import carbon from "../assets/img/dashboard/carbon.svg";
import energy from "../assets/img/dashboard/energy.svg";
import {
  IconCheck,
  IconClock,
  IconCircle,
} from "@tabler/icons-react";

function Card({ status, paso, subpaso }) {
  return (
    <div className="flex flex-col bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 col-span-1 lg:col-span-2">
      {/* Paso 1 */}
      {status === "iniciada" && paso === 1 ? (
        <div className="flex flex-col items-center justify-center py-6 rounded-xl max-w-md mx-auto bg-gray-100 dark:bg-gray-900">
          <h2 className="text-lg font-bold text-gray-800 dark:text-gray-100">
            Fases
          </h2>

          {/* Fases */}
          <div className="flex justify-between w-full mt-6 px-4">
            {/* Fase 1 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-blue-600 dark:text-blue-500">
                1
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-blue-100 dark:bg-blue-900">
                <div className="w-5 h-5 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-700 dark:text-gray-100">
                Solicitud <br /> Renting Solar
              </p>
            </div>

            {/* Fase 2 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-gray-500 dark:text-gray-400">
                2
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-gray-200 dark:bg-gray-700">
                <div className="w-2.5 h-2.5 rounded-full bg-gray-500 dark:bg-gray-400"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                Firma de <br /> Contrato
              </p>
            </div>

            {/* Fase 3 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-gray-500 dark:text-gray-400">
                3
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-gray-200 dark:bg-gray-700">
                <div className="w-2.5 h-2.5 rounded-full bg-gray-500 dark:bg-gray-400"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                Instalación <br /> Planta Solar
              </p>
            </div>
          </div>

          {/* Subfases */}
          <div className="w-full mt-8 px-4">
            <h3 className="text-base font-bold text-gray-800 dark:text-gray-100 text-center">
              Solicitud Renting Solar
            </h3>
            <div className="mt-4 space-y-4">
              {/* Subpaso 1 */}
              <div className="flex items-center">
                {subpaso >= 1 ? (
                  <IconCheck className="w-5 h-5 text-green-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 1
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Solicitud de Solar Flex
                </span>
              </div>

              {/* Subpaso 2 */}
              <div className="flex items-center">
                {subpaso >= 2 ? (
                  <IconCheck className="w-5 h-5 text-green-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 2
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Confirmación de solicitud
                </span>
              </div>

              {/* Subpaso 3 */}
              <div className="flex items-center">
                {subpaso >= 3 ? (
                  <IconClock className="w-5 h-5 text-yellow-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 3
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Agendamiento de visita técnica
                </span>
              </div>

              {/* Subpaso 4 */}
              <div className="flex items-center">
                {subpaso >= 4 ? (
                  <IconClock className="w-5 h-5 text-yellow-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 4
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Visita técnica
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : status === "iniciada" && paso === 2 ? (
        /* Paso 2 */
        <div className="flex flex-col items-center justify-center py-6 rounded-xl max-w-md mx-auto bg-gray-100 dark:bg-gray-900">
          <h2 className="text-lg font-bold text-gray-800 dark:text-gray-100">
            Fases
          </h2>

          {/* Fases */}
          <div className="flex justify-between w-full mt-6 px-4">
            {/* Fase 1 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-gray-500 dark:text-gray-400">
                1
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-gray-200 dark:bg-gray-700">
                <div className="w-2.5 h-2.5 rounded-full bg-gray-500 dark:bg-gray-400"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                Solicitud <br /> Renting Solar
              </p>
            </div>

            {/* Fase 2 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-blue-600 dark:text-blue-500">
                2
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-blue-100 dark:bg-blue-900">
                <div className="w-5 h-5 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-700 dark:text-gray-100">
                Firma de <br /> Contrato
              </p>
            </div>

            {/* Fase 3 */}
            <div className="flex flex-col items-center">
              <span className="text-xl font-extrabold text-gray-500 dark:text-gray-400">
                3
              </span>
              <div className="flex items-center justify-center w-10 h-10 mt-2 rounded-full bg-gray-200 dark:bg-gray-700">
                <div className="w-2.5 h-2.5 rounded-full bg-gray-500 dark:bg-gray-400"></div>
              </div>
              <p className="mt-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-400">
                Instalación <br /> Planta Solar
              </p>
            </div>
          </div>

          {/* Subfases */}
          <div className="w-full mt-8 px-4">
            <h3 className="text-base font-bold text-gray-800 dark:text-gray-100 text-center">
              Firma de Contrato
            </h3>
            <div className="mt-4 space-y-4">
              {/* Subpaso 1 */}
              <div className="flex items-center">
                {subpaso >= 1 ? (
                  <IconCheck className="w-5 h-5 text-green-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 1
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Presentación del Contrato
                </span>
              </div>

              {/* Subpaso 2 */}
              <div className="flex items-center">
                {subpaso >= 2 ? (
                  <IconClock className="w-5 h-5 text-yellow-500" />
                ) : (
                  <IconCircle className="w-5 h-5 text-gray-400" />
                )}
                <span
                  className={`ml-3 text-sm ${
                    subpaso >= 2
                      ? "text-gray-700 dark:text-gray-100"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Firma del Contrato
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* Paso 3 o Default */
        <div className="flex flex-col items-center text-center py-6">
          <h2 className="text-lg font-extrabold text-gray-800 dark:text-gray-100">
            Proceso de solicitud SolarFlex
          </h2>
          <p className="mt-4 text-sm font-semibold text-gray-700 dark:text-gray-300">
            Solicitud Servicio Solar Flex
          </p>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Disfruta de energía limpia sin inversión inicial. Transforma tu hogar
            de manera sostenible y ahorra desde el primer día.
          </p>
          <p className="mt-2 italic text-sm text-gray-700 dark:text-gray-300">
            ¡Activa tu plan ahora y genera energía verde!
          </p>

          {/* Beneficios */}
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mt-8 w-full max-w-2xl">
            {/* Beneficio 1 */}
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full">
                <img src={llaves} alt="Mantenimiento Gratuito" className="w-8 h-8" />
              </div>
              <span className="mt-3 text-sm font-medium text-gray-700 dark:text-gray-100">
                Mantenimiento Gratuito
              </span>
            </div>

            {/* Beneficio 2 */}
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full">
                <img src={dolar} alt="Sin Inversión Inicial" className="w-8 h-8" />
              </div>
              <span className="mt-3 text-sm font-medium text-gray-700 dark:text-gray-100">
                Sin Inversión Inicial
              </span>
            </div>

            {/* Beneficio 3 */}
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full">
                <img src={carbon} alt="Impacto Ambiental Positivo" className="w-8 h-8" />
              </div>
              <span className="mt-3 text-sm font-medium text-gray-700 dark:text-gray-100">
                Impacto Ambiental Positivo
              </span>
            </div>

            {/* Beneficio 4 */}
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full">
                <img src={energy} alt="Energía Limpia y Renovable" className="w-8 h-8" />
              </div>
              <span className="mt-3 text-sm font-medium text-gray-700 dark:text-gray-100">
                Energía Limpia y Renovable
              </span>
            </div>
          </div>

          {/* Botón de Solicitud */}
          <Link
            to="/solicitar-renting"
            className="mt-8 px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-300"
          >
            Solicitar
          </Link>
        </div>
      )}
    </div>
  );
}

export default Card;
