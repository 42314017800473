import Footer from "../components/navigation/Footer";
import DashboardLayout from "../hocs/layout/DashboardLayout";

import Header from "../components/perfil/Profile"
import Informacion from "../components/perfil/Informacion";
import Plantas from "../components/perfil/Plantas";
function Profile() {
  return (
    <DashboardLayout>
      <div className="flex flex-col p-6 bg-white dark:bg-[#282C34] rounded-2xl">
        
        <Header perfil={true}/>
        {/* Sección de tarjetas principales */}

        <Informacion />
        {/* Sección de las tarjetas de las plantas */}

        
        <Plantas />
      </div>
    </DashboardLayout>
  );
}

export default Profile;
