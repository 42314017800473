import {
  IconMail,
  IconShieldLock,
  IconKey,
  IconPencil,
} from "@tabler/icons-react";
import img from "../../assets/img/perfil.png"
import { useSelector } from "react-redux";
function Profile({ perfil }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="flex flex-col w-full rounded-none">
      <div className="flex flex-wrap gap-5 justify-between px-4 py-5 w-full rounded-2xl border-2 border-white bg-[#F4F7FB] dark:bg-[#333844] dark:border-[#282C34] border-solid shadow-md">
        <div className="relative flex gap-5 leading-snug">
          <div className="relative">
            <img
              loading="lazy"
              src={img}
              className="object-contain shrink-0 max-w-full rounded-lg w-[107px] dark:bg-[#282C34]"
            />
            {perfil && perfil && (
              <button className="absolute bottom-1 right-1 bg-white dark:bg-gray-700 p-1 rounded-full shadow-md">
                <IconPencil
                  size={16}
                  className="text-gray-700 dark:text-gray-300"
                />
              </button>
            )}
          </div>
          <div className="flex flex-col my-auto">
            <div className="text-2xl font-poppins-bold text-black dark:text-white">
              {user && user.fullName}
            </div>
            <div className="self-start text-base font-poppins-medium text-gray-600 dark:text-gray-400">
              {
                user && user.rol === "admin" ? "Admin Watti" : user&&user.rol === "epc" ? "EPC Watti" : "Cliente Watti"
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Profile;
