import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../components/Alert";
import {
  login,
  isAuthenticated,
  refresh,
  load_user,
} from "../../redux/actions/auth/auth";
import bg from "../../assets/img/bg.svg";
import Footer from "../../components/auth/Footer";
import NavBar from "../../components/auth/NavBar";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

function Login({ login, isAuthenticated, refresh, load_user }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const role = sessionStorage.getItem('role');
    
  
      let redirectPath = "/inicio"; // Default redirect path
  
      if (role === 'admin') {

        redirectPath = "/admin/inicio";
      }
       else if (role === 'cliente') {
        redirectPath = "/inicio";
      } else if (role === 'epc') {
        redirectPath = "/epc-inicio";
      }
  
      Toast({ tipo: "success", mensaje: "¡Bienvenido!, has iniciado sesión." });
      navigate(redirectPath);
    } else {
      refresh();
      load_user();
    }
  }, [isAuthenticated, navigate, refresh, load_user]);
  

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="m-0 font-sans antialiased font-normal bg-white text-start text-base leading-default text-slate-500">
      <NavBar />
      <main className="mt-0 transition-all duration-200 ease-soft-in-out">
        <section>
          <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="container z-10">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 mt-32 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                      <h1
                        className="relative z-10 font-poppins-bold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                        style={{ fontSize: "32px" }}
                      >
                        Bienvenido
                      </h1>
                      <p className="mb-0">
                        Ingresa tu correo electrónico y contraseña para iniciar
                        sesión
                      </p>
                    </div>
                    <div className="flex-auto p-6">
                      <form role="form" onSubmit={onSubmit}>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Correo electrónico
                        </label>
                        <div className="mb-4">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={onChange}
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Correo electrónico"
                            aria-label="Correo electrónico"
                            aria-describedby="email-addon"
                          />
                        </div>
                        <label className="mb-2 ml-1 font-poppins-bold text-xs text-slate-700">
                          Contraseña
                        </label>
                        <div className="mb-4 relative">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={onChange}
                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-500 focus:outline-none focus:transition-shadow"
                            placeholder="Contraseña"
                            aria-label="Contraseña"
                            aria-describedby="password-addon"
                          />
                          <span
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <IconEyeOff size={18} />
                            ) : (
                              <IconEye size={18} />
                            )}
                          </span>
                        </div>
                        <div className="min-h-6 mb-0.5 block pl-12">
                          <input
                            id="rememberMe"
                            className="mt-0.54 rounded-full duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={handleSetRememberMe}
                          />
                          <label
                            className="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700"
                            htmlFor="rememberMe"
                          >
                            Recuérdame
                          </label>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="inline-block w-full px-6 py-3 mt-6 mb-0 font-poppins-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-blue-600 to-cyan-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
                          >
                            Iniciar sesión
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="p-6 px-1 pt-0 text-center bg-transparent border-t-0 border-t-solid rounded-b-2xl lg:px-2">
                      <p className="mx-auto mb-6 leading-normal text-sm">
                        ¿No tienes una cuenta?{" "}
                        <Link
                          to="/register"
                          className="relative z-10 font-poppins-semibold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                        >
                          Regístrate
                        </Link>
                      </p>
                      <p className="mx-auto mb-6 leading-normal text-sm">
                        <Link
                          to="/forgot-password"
                          className="relative z-10 font-poppins-semibold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                        >
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-full px-3 my-auto shrink-0 md:flex-0 md:w-6/12">
                  <div className="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                    <div
                      className="skew-x-10 bg-contain bg-left-bottom -ml-16 h-full absolute inset-x-0 top-0 "
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${bg})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  login,
  refresh,
  load_user,
})(Login);
