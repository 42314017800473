import { useEffect, useState, useRef } from "react";
import Footer from "../../components/auth/Footer";
import NavBar from "../../components/auth/NavBar";
import bg from "../../assets/img/bg.svg";
import axios from "axios";
import Toast from "../../components/Alert";
import { useNavigate } from "react-router-dom";

function TokenVerify() {
  const navigate = useNavigate();
  const [token, setToken] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1) {
      setToken((prevToken) => {
        const newToken = [...prevToken];
        newToken[index] = value;
        return newToken;
      });
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && token[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleResendCode = async () => {
    const userId = localStorage.getItem("userID");
    if (!userId) {
      console.error("User ID not found in local storage.");
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/reenviar-codigo/${userId}`);
      if (response.status === 200) {
        Toast({ tipo: "success", mensaje: "¡Código reenviado correctamente! Revisa tu correo." });
      }
    } catch (error) {
      console.error("Error resending code:", error);
      Toast({ tipo: "error", mensaje: "Hubo un error al reenviar el código." });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const tokenString = token.join("");
    const tokenNumber = parseInt(tokenString, 10);
    const userId = localStorage.getItem("userID");
    if (!userId) {
      console.error("User ID not found in local storage.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/verificar`, {
        token: tokenNumber,
        usuario_id: userId,
      });
      if (response.data.statusCode === 201) {
        localStorage.removeItem("userID");
        Toast({ tipo: "success", mensaje: "¡Correo verificado correctamente! Inicia Sesión" });
        navigate("/");
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    }
  };

  return (
    <main className="mt-0 transition-all duration-200 ease-soft-in-out">
      <NavBar />
      <section>
        <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
          <div className="container z-10">
            <div className="flex flex-wrap mt-0 -mx-3">
              <div className="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                <div className="relative flex flex-col min-w-0 mt-32 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                  <div className="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl text-center">
                    <h3
                      className="relative z-10 font-poppins-bold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text"
                      style={{ fontSize: "32px" }}
                    >
                      Confirmación de correo electrónico
                    </h3>
                  </div>
                  <div className="flex-auto p-6">
                    <form role="form" onSubmit={onSubmit}>
                      <div className="mb-4 flex space-x-2 justify-center">
                        {Array.from({ length: 4 }).map((_, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={token[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="text-sm leading-5.6 ease-soft block w-16 h-16 appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-blue-400 focus:outline-none text-center shadow-inner shadow-neutral-300"
                          />
                        ))}
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="inline-block w-full px-6 py-3 mt-6 mb-0 font-poppins-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-gradient-to-tl from-blue-600 to-cyan-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
                        >
                          Enviar Código
                        </button>
                      </div>
                    </form>
                    <div className="text-center mt-4">
                      <p className="text-sm text-slate-700">
                        ¿No recibiste ningún correo?
                      </p>
                      <a
                        href="#"
                        onClick={handleResendCode}
                        className="text-sm text-blue-600 hover:underline"
                      >
                        Presiona aquí
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full max-w-full px-3 lg:flex-0 shrink-0 md:w-6/12">
                <div className="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                  <div
                    className="absolute inset-x-0 top-0 z-0 h-full -ml-16 bg-cover skew-x-10"
                    style={{
                      backgroundImage: `url(${bg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default TokenVerify;
