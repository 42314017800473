import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PropTypes from "prop-types";

const MapboxExample = React.memo(({ onUpdate }) => {
  const mapContainerRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoianVhbnRpY2siLCJhIjoiY2x5aGVvOTU2MDN1MjJscTI0aWI3aDg3YyJ9.i8ma720LaEpOnmFxyCIk9A';

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/juantick/clxuxn6i904ec01qme1kldv2m',
      center: [-72.5078, 7.8891],
      zoom: 15.5,
      pitch: 45,
      bearing: -17.6,
      antialias: true,
      language: 'es'
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      language: 'es',
      countries: 'co',
      bbox: [-72.545, 7.851, -72.47, 7.923],
      placeholder: 'Buscar en Cúcuta',
    });
    mapRef.current.addControl(geocoder, 'top-left');
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    mapRef.current.addControl(new mapboxgl.ScaleControl(), 'bottom-left');
    mapRef.current.addControl(new mapboxgl.FullscreenControl());

    const marker = new mapboxgl.Marker({
      draggable: true
    })
      .setLngLat([-72.5078, 7.8891])
      .addTo(mapRef.current);

    function onDragEnd() {
      const lngLat = marker.getLngLat();
   
      onUpdate(lngLat.lng, lngLat.lat); // Call the passed onUpdate function
    }

    marker.on('dragend', onDragEnd);

    mapRef.current.on('style.load', () => {
      const layers = mapRef.current.getStyle().layers;
      if (layers) {
        const labelLayer = layers.find(layer => layer.type === 'symbol' && layer.layout['text-field']);
        if (labelLayer) {
          const labelLayerId = labelLayer.id;

          mapRef.current.addLayer({
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
              ],
              'fill-extrusion-opacity': 0.6
            }
          }, labelLayerId);
        }
      }

      mapRef.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14
      });
      mapRef.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    });

    return () => mapRef.current.remove();
  }, [onUpdate]);

  return <div ref={mapContainerRef} style={{ height: '50vh', width: '100%' }} />;
});

MapboxExample.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default MapboxExample;
