import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  LOGOUT,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
} from "../actions/auth/types";

const initialState = {
  id: null,
  token: null,
  isAuthenticated: false,
  user: null,
  user_loading: true,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
        user_loading: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
        user_loading: false,
      };
    case LOGIN_SUCCESS:
      const user = payload.message[0];
      sessionStorage.setItem("id", user.id);
      sessionStorage.setItem("token", user.token);
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("role", user.rol);
      return {
        ...state,
        id: user.id,
        rol : user.rol,
        is_email_verificado : user.is_email_verificado,
        token: user.token,
        isAuthenticated: true,
      };

    case LOGIN_FAIL:
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("isAuthenticated");

      return {
        ...state,
        id: null,
        rol: null,
        is_email_verificado: null,
        token: null,
        isAuthenticated: false,
      };

    case REFRESH_TOKEN_SUCCESS:
      sessionStorage.setItem("token", payload.message);
      sessionStorage.setItem("isAuthenticated", true);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
      };

    case REFRESH_TOKEN_FAIL:
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("isAuthenticated");
      return {
        ...state,
        id: null,

        token: null,
        isAuthenticated: false,
      };

    case LOGOUT:
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("isAuthenticated");
      return {
        ...state,
        id: null,

        token: null,
        isAuthenticated: false,
      };

    default:
      return state;
  }
}
