import React from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { IconMail, IconShieldCheck, IconKey, IconLock, IconEdit } from "@tabler/icons-react";
import Header from "../../components/perfil/Profile";
import { useSelector } from "react-redux";

function AdminPerfil() {
  const user = useSelector((state) => state.auth.user);
  return (
    <DashboardLayout>
      <div className="flex flex-col items-center p-5 bg-white dark:bg-gray-900 rounded-xl shadow-lg ">
        <Header perfil={true} />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-4 w-full">
          <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-xl shadow">
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">Información Personal</h3>
            <div className="space-y-2">
              <div className="flex justify-between text-gray-900 dark:text-gray-300">
                <span>ID</span>
                <span>{sessionStorage.getItem('id')}</span>
              </div>
              <div className="flex justify-between text-gray-900 dark:text-gray-300">
                <span>Correo</span>
                <span>{user&&user.email}</span>
              </div>
              <div className="flex justify-between text-gray-900 dark:text-gray-300">
                <span>Nombre</span>
                <span>{user&&user.fullName}</span>
              </div>
              <div className="flex justify-between text-gray-900 dark:text-gray-300">
                <span>Teléfono</span>
                <span>316-524 5268</span>
              </div>
              <div className="flex justify-between text-gray-900 dark:text-gray-300">
                <span>Ciudad</span>
                <span>Cúcuta - Norte de Santander</span>
              </div>
            </div>
            <button className="w-full mt-4 p-2 bg-[#00A1FF] text-white rounded-md">
              Editar Información
            </button>
          </div>

          {/* Seguridad */}
          <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-xl shadow">
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">Seguridad</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconMail size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Correo electrónico</span>
                </div>
                <span className="text-green-500">Verificado</span>
              </div>
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconShieldCheck size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Autenticación de dos factores (2FA)</span>
                </div>
                <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
              </div>
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconKey size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Contraseña</span>
                </div>
                <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
              </div>
            </div>
          </div>

          {/* Configuración de Privacidad */}
          <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-xl shadow">
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">Configuración de Privacidad</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Términos y condiciones</span>
                </div>
                <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
              </div>
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Política de privacidad</span>
                </div>
                <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
              </div>
              <div className="flex items-center justify-between text-gray-900 dark:text-gray-300">
                <div className="flex items-center space-x-3">
                  <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
                  <span>Política de cookies</span>
                </div>
                <IconLock size={20} className="text-gray-500 dark:text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AdminPerfil;
