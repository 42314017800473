import { format, parse, startOfWeek, getDay } from "date-fns";
import { es } from "date-fns/locale";
import { fromZonedTime, toDate } from "date-fns-tz";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  es: es,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const bogotaTimeZone = 'America/Bogota';

const events = [
  {
    title: "Visita Realizada",
    start: fromZonedTime(new Date(2024, 7, 19, 10, 0), bogotaTimeZone),
    end: fromZonedTime(new Date(2024, 7, 19, 12, 0), bogotaTimeZone),
    allDay: false,
    resource: "completed",
  },
  // Agrega más eventos aquí
];

const eventStyleGetter = (event, start, end, isSelected) => {
  let backgroundColor = "#3174ad";
  if (event.resource === "completed") {
    backgroundColor = "#00ff00"; // Verde para visitas realizadas
  } else if (event.resource === "pending") {
    backgroundColor = "#ff0000"; // Rojo para visitas pendientes
  } else if (event.resource === "available") {
    backgroundColor = "#00ffff"; // Cian para agendas disponibles
  }

  const style = {
    backgroundColor,
    borderRadius: "5px",
    opacity: 0.8,
    color: "black",
    border: "0px",
    display: "block",
  };

  return {
    style: style,
  };
};

function MyCalendar() {
  return (
    <div className="flex gap-4 mt-4 w-full">
      <div className="px-5 pt-5 pb-10 rounded-xl bg-gray-200 dark:bg-gray-800 w-full">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "auto", width: "100%" }}
          eventPropGetter={eventStyleGetter}
          views={["week"]}
          defaultView="week"
          timeslots={1}
          step={60}
          min={new Date(2024, 7, 19, 8, 0)}
          max={new Date(2024, 7, 19, 18, 0)}
          defaultDate={toDate(new Date(), { timeZone: bogotaTimeZone })}
          messages={{
            next: "Sig",
            previous: "Ant",
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
          }}
          dayPropGetter={(date) => {
            // Deshabilitar domingos y horas no laborales
            const disabled = date.getDay() === 0 || (date.getHours() === 12 && date.getMinutes() === 0);
            return {
              className: disabled ? "rbc-day-disabled" : "",
              style: {
                backgroundColor: disabled ? '#f0f0f0' : '',
              },
            };
          }}
        />
      </div>

     
    </div>
  );
}

export default MyCalendar;
