import reloj from '../../assets/img/monitoreo/reloj.svg';
import hoja from '../../assets/img/monitoreo/hoja.svg';
import carbono from '../../assets/img/monitoreo/carbono.svg';
import reloj2 from '../../assets/img/monitoreo/reloj2.svg';

function Produccion() {
  return (
    <div className="flex justify-center items-center text-center rounded-lg bg-[#F4F7FB] dark:bg-[#282C34] border-opacity-10 max-w-full shadow-[0px_20px_40px_rgba(59,64,86,0.15)] p-3 w-full lg:w-4/12">
      <div className="grid grid-cols-2 gap-4 w-full h-full">
        <div className="flex flex-col gap-2 justify-center items-center p-2 rounded-2xl border border-solid bg-zinc-200 border-white border-opacity-10 dark:bg-gray-800">
          <img src={reloj} alt="reloj" className="w-12 h-12 lg:w-14 lg:h-14" />
          <div className="text-xs font-poppins-semibold text-sky-500">
            Conservación Forestal
          </div>
          <div className="font-poppins-bold leading-none text-stone-50">
            <span className="text-2xl lg:text-3xl text-gray-400">4% </span> 
            <span className="text-lg lg:text-xl text-gray-400 font-poppins-medium">CO2eq</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center p-2 rounded-2xl border border-solid bg-zinc-200 border-white border-opacity-10 dark:bg-gray-800">
          <img src={hoja} alt="hoja" className="w-12 h-12 lg:w-14 lg:h-14" />
          <div className="text-xs font-poppins-semibold text-sky-500">
            Conservación Forestal
          </div>
          <div className="text-2xl lg:text-3xl font-poppins-bold leading-none text-gray-400">
            271%
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center p-2 rounded-2xl border border-solid bg-zinc-200 border-white border-opacity-10 dark:bg-gray-800">
          <img src={carbono} alt="carbono" className="w-12 h-12 lg:w-14 lg:h-14" />
          <div className="text-xs font-poppins-semibold text-sky-500">
            Carbono Reducido
          </div>
          <div className="font-poppins-bold leading-none text-stone-50">
            <span className="text-2xl lg:text-3xl text-gray-400">4% </span> 
            <span className="text-lg lg:text-xl text-gray-400 font-poppins-medium">CO2eq</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center p-2 rounded-2xl border border-solid bg-zinc-200 border-white border-opacity-10 dark:bg-gray-800">
          <img src={reloj2} alt="reloj2" className="w-12 h-12 lg:w-14 lg:h-14" />
          <div className="text-xs font-poppins-semibold text-sky-500">
            Total de Horas
          </div>
          <div className="font-poppins-bold leading-none text-gray-400">
            <span className="text-2xl lg:text-3xl">80 </span>
            <span className="text-lg font-poppins-medium text-gray-400">Hr</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Produccion;
