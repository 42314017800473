import React, { useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import {
  IconChevronRight,
  IconChevronDown,
  IconBrandWhatsapp,
  IconMessageCircle,
  IconPhone,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import ChatBubble from "../containers/chat";
import CallForm from "../components/soporte/FormularioLlamada";  // Importa el nuevo componente CallForm

function Soporte() {
  const [faqOpen, setFaqOpen] = useState({});
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [showCallForm, setShowCallForm] = useState(false);

  const faqs = [
    {
      question: "¿Qué es Watti?",
      answer:
        "Watti es una empresa dedicada a ofrecer soluciones de energía solar a través de un modelo de suscripción mensual. Permitimos a los usuarios acceder a equipos que generan energía solar sin tener que adquirirlos, beneficiándose de energía limpia y ahorros en su factura de electricidad.",
    },
    {
      question: "¿Cómo funciona la suscripción solar de Watti?",
      answer:
        "Nuestro servicio de suscripción mensual te permite disponer de energía solar sin el costo inicial de compra. Watti instala y mantiene el sistema, mientras tú pagas una tarifa mensual que suele ser menor (un ahorro del 40% aprox) a lo que pagarías en electricidad convencional.",
    },
    {
      question: "¿Qué incluye la suscripción mensual?",
      answer:
        "La suscripción mensual incluye la instalación y mantenimiento de los paneles solares, acceso a nuestra plataforma tecnológica para monitorear tu consumo de energía, recibir alertas y notificaciones, pagar facturas, y solicitar servicios adicionales. También puedes gestionar referidos y hacer un seguimiento del proceso constructivo si estás en la fase de instalación.",
    },
    {
      question: "¿Puedo comprar el sistema solar fotovoltaico después de un tiempo?",
      answer:
        "Sí, después de cierto periodo puedes optar por comprar la planta solar si así lo decides. Consulta las condiciones específicas dentro de la app o contacta a nuestro equipo de atención al cliente.",
    },
    {
      question: "¿Cómo monitoreo el rendimiento de mi sistema solar fotovoltaico?",
      answer:
        "A través de la app de Watti puedes ver un tablero de consumo donde se muestra el rendimiento de tu sistema, la energía generada y los ahorros obtenidos en tiempo real.",
    },
    {
      question: "¿Qué es Watti Smart World?",
      answer:
        "Watti Smart World es un módulo dentro de la app donde los usuarios pueden invertir en proyectos de energía renovable. A través de este módulo, puedes apoyar nuevas instalaciones de energía solar y recibir un rendimiento mensual sobre tu inversión.",
    },
    {
      question: "¿Cómo puedo invertir en Watti Smart World?",
      answer:
        "Dentro de la app, accede al módulo de Watti Smart World y explora los proyectos disponibles para invertir. Una vez seleccionado un proyecto, podrás ver detalles sobre la rentabilidad y plazos. Las transacciones se realizan utilizando nuestro token interno, Watti.",
    },
    {
      question: "¿Qué beneficios obtengo al referir nuevos usuarios?",
      answer:
        "Al referir nuevos usuarios que se suscriban a nuestros servicios, acumulas energía en tu batería virtual. Esto te permitirá avanzar de nivel en nuestra plataforma y acceder a descuentos y beneficios exclusivos.",
    },
    {
      question: "¿Cómo puedo contactarlos para resolver una duda o problema?",
      answer:
        "Puedes contactarnos a través de la sección de 'Soporte' en la app, donde podrás crear un ticket de ayuda. También puedes comunicarte por nuestras líneas de atención telefónica o correo electrónico, disponibles en la app.",
    },
    {
      question: "¿Cuál es la política de cancelación del servicio?",
      answer:
        "Puedes cancelar el servicio en cualquier momento. Sin embargo, te recomendamos revisar los términos y condiciones aplicables en tu contrato de suscripción mensual para conocer las posibles implicaciones o costos asociados.",
    },
    {
      question: "¿Cómo funciona el sistema de niveles en la app?",
      answer:
        "El sistema de niveles te permite desbloquear beneficios a medida que llenas tu batería virtual con acciones como referir usuarios, invertir en proyectos o completar tu perfil. Cada nivel ofrece diferentes descuentos y ventajas dentro de la app.",
    },
    {
      question: "¿Puedo recibir y pagar mis facturas a través de la plataforma?",
      answer:
        "Sí, puedes recibir y pagar tus facturas directamente a través de nuestro plataforma web o aplicación móvil. El proceso es rápido, seguro y conveniente, permitiéndote gestionar tus pagos sin salir de la plataforma.",
    },
    {
      question: "¿Cómo puedo hacer un seguimiento del proceso de instalación de mis paneles solares?",
      answer:
        "Si estás en la fase de instalación, puedes hacer un seguimiento del proceso de instalación y puesta en marcha a través de nuestra app. Te proporcionamos actualizaciones en tiempo real sobre cada etapa del proyecto, desde la programación hasta la finalización.",
    },
    {
      question: "¿Qué pasa si me mudo a una nueva casa?",
      answer:
        "Si te mudas a una nueva casa, recomendamos contactar al equipo de atención al cliente para evaluar la posibilidad de trasladar tu sistema solar a la nueva ubicación. Nuestro equipo te guiará en el proceso y te informará sobre cualquier costo asociado con el traslado.",
    },
    {
      question: "¿Puedo acceder a la plataforma desde varios dispositivos?",
      answer:
        "Sí, puedes acceder a nuestra plataforma desde múltiples dispositivos, ya sea un ordenador, tablet o smartphone. Solo necesitas tus credenciales de inicio de sesión para acceder a tu cuenta desde cualquier lugar.",
    },
    {
      question: "¿Qué tipos de paneles solares utilizan?",
      answer:
        "En Watti Energy utilizamos paneles solares y elementos certificados con la Tier 1, lo que garantiza la alta eficiencia y calidad, seleccionados para maximizar la producción de energía en las condiciones específicas de tu ubicación. Trabajamos con proveedores confiables para asegurar que nuestros clientes reciban la mejor tecnología disponible.",
    },
    {
      question: "¿Cómo se garantiza el mantenimiento de los paneles solares?",
      answer:
        "Watti Energy se encarga de todo el mantenimiento necesario para asegurar que tu sistema solar funcione de manera óptima. Esto incluye revisiones periódicas, limpieza de paneles y reparaciones en caso de que sean necesarias. Cualquier servicio de mantenimiento será coordinado a través de nuestra plataforma.",
    },
    {
      question: "¿Cómo protege Watti Energy mis datos personales?",
      answer:
        "En Watti Energy, tomamos muy en serio la seguridad de tus datos. Implementamos medidas de seguridad avanzadas para proteger tu información personal y garantizar que solo tú tengas acceso a tus datos. Puedes leer más sobre nuestras políticas de privacidad en la plataforma.",
    },
    {
      question: "¿Qué ocurre al final de mi contrato de suscripción?",
      answer:
        "Al final de tu contrato de suscripción, puedes optar por renovarlo, actualizarlo o, si lo prefieres, cancelar el servicio. Te contactaremos con antelación para discutir las opciones y asegurarnos de que estés satisfecho con la continuación del servicio.",
    },
  ];
  

  const toggleFaq = (index) => {
    setFaqOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleChat = () => {
    setIsChatVisible(!isChatVisible);
  };

  const handleCallSelection = () => {
    setShowCallForm(true);  // Muestra el formulario de llamada
  };

  const handleBack = () => {
    setShowCallForm(false);  // Oculta el formulario de llamada y regresa al menú principal
  };

  if (showCallForm) {
    return <CallForm onBack={handleBack} />;
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center pt-12  bg-white dark:bg-gray-900 text-black dark:text-white ">
        <div className="flex flex-col max-w-full font-medium w-[982px]">
          <div className="self-start text-4xl leading-none text-center max-md:max-w-full">
            Soporte al Cliente
          </div>
          <div className="mt-5 text-lg leading-7 max-md:max-w-full">
            Para asegurarnos de que recibas la atención que mereces, por favor
            completa la información a continuación y te llamaremos en el momento
            que mejor te convenga. ¡Estamos aquí para asistirte!
          </div>
        </div>
        <div className="flex flex-col mt-12 max-w-full w-[982px] max-md:mt-10">
          <div className="flex flex-col self-center px-2 max-w-full text-xl font-medium leading-none w-[798px]">
            <div
              className="flex flex-col justify-center px-4 py-3.5 w-full rounded-md border-2 border-sky-500 border-solid min-h-[62px] shadow-[0px_4px_23px_rgba(0,161,255,0.2)] bg-white dark:bg-gray-800 dark:border-gray-700 max-md:max-w-full cursor-pointer"
              onClick={toggleChat}
            >
              <div className="flex gap-2 items-center w-full max-md:max-w-full">
                <div className="flex flex-wrap flex-1 shrink gap-10 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 shrink gap-6 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
                    <IconMessageCircle className="shrink-0 self-stretch my-auto w-9 aspect-square text-sky-500" />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                      Chatea con nosotros
                    </div>
                  </div>
                  <IconChevronRight className="shrink-0 self-stretch my-auto w-1.5 aspect-[0.5] text-sky-500" />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center px-4 py-3.5 mt-6 w-full rounded-md border-2 border-sky-500 border-solid min-h-[62px] shadow-[0px_4px_23px_rgba(0,161,255,0.2)] bg-white dark:bg-gray-800 dark:border-gray-700 max-md:max-w-full">
              <div className="flex gap-2 items-center w-full max-md:max-w-full">
                <div className="flex flex-wrap flex-1 shrink gap-10 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 shrink gap-6 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
                    <IconBrandWhatsapp className="shrink-0 self-stretch my-auto w-9 aspect-square text-sky-500" />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                      Hablemos por Whatsapp
                    </div>
                  </div>
                  <IconChevronRight className="shrink-0 self-stretch my-auto w-1.5 aspect-[0.5] text-sky-500" />
                </div>
              </div>
            </div>
            <div
              className="flex flex-col justify-center px-4 py-3.5 mt-6 w-full rounded-md border-2 border-sky-500 border-solid min-h-[62px] shadow-[0px_4px_23px_rgba(0,161,255,0.2)] bg-white dark:bg-gray-800 dark:border-gray-700 max-md:max-w-full cursor-pointer"
              onClick={handleCallSelection}
            >
              <div className="flex gap-2 items-center w-full max-md:max-w-full">
                <div className="flex flex-wrap flex-1 shrink gap-10 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 shrink gap-6 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
                    <IconPhone className="shrink-0 self-stretch my-auto w-9 aspect-square text-sky-500" />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                      Nosotros te llamamos
                    </div>
                  </div>
                  <IconChevronRight className="shrink-0 self-stretch my-auto w-1.5 aspect-[0.5] text-sky-500" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-16 w-full text-base max-md:mt-10 max-md:max-w-full">
            <div className="w-full text-2xl font-bold leading-tight text-center text-gray-900 dark:text-gray-50 max-md:max-w-full">
              Preguntas Frecuentes FAQs
            </div>
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="flex flex-col mt-5 w-full max-md:max-w-full"
              >
                <div
                  className="flex flex-wrap gap-3 items-center w-full font-semibold leading-tight text-gray-900 dark:text-white max-md:max-w-full cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <div className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                    {faq.question}
                  </div>
                  {faqOpen[index] ? (
                    <IconChevronDown className="shrink-0 self-stretch my-auto w-6 aspect-square text-sky-500" />
                  ) : (
                    <IconChevronRight className="shrink-0 self-stretch my-auto w-6 aspect-square text-sky-500" />
                  )}
                </div>
                {faqOpen[index] && (
                  <div className="mt-3 leading-5 text-justify text-gray-600 dark:text-slate-400 max-md:max-w-full">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {isChatVisible && <ChatBubble />} {/* Renderiza el chat solo si está visible */}
      </div>
    </DashboardLayout>
  );
}

export default Soporte;
