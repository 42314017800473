import React from "react";

import DashboardLayout from "../../hocs/layout/DashboardLayout";
import PanelControl from "../../components/referidos/PanelControl";
import ComoFunciona from "../../components/referidos/ComoFunciona";
import Reglas from "../../components/referidos/Reglas";
import MisReferidos from "../../components/referidos/MisReferidos";
import PreguntasFrecuentes from "../../components/referidos/PreguntasFrecuentes";

function Referidos() {


  return (
    <DashboardLayout>
      <div className="flex flex-col p-6 bg-white dark:bg-[#282C34] rounded-2xl">
        <div className="flex overflow-hidden flex-col">
          <div className="flex flex-col w-full max-md:max-w-full">
            <div className="text-base font-poppins-semibold tracking-tight leading-none text-slate-800 dark:text-white">
              Programa de referidos
            </div>
            <div className="mt-1.5 text-sm text-slate-400 max-md:max-w-full dark:text-gray-300">
              Sigue y encuentra todos los detalles sobre nuestro programa de
              referidos, tus estadísticas y tus recompensas.
            </div>
          </div>
        </div>

        <ComoFunciona />

        <PanelControl />

        <Reglas />

        <MisReferidos />

        <PreguntasFrecuentes />
      </div>
    </DashboardLayout>
  );
}

export default Referidos;
