import React from "react";
import { Link } from "react-router-dom";
import {
  IconBrandInstagram,
  IconBrandX,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandYoutube
} from "@tabler/icons-react";
import logo from "../../assets/img/logo-full-blue.png";

function Footer() {
  return (
    <footer className="py-12 bg-white dark:bg-[#333844]">
      <div className="flex-shrink-0 w-full max-w-full mx-auto mt-2 mb-6 text-center lg:flex-0 lg:w-8/12">
        <img src={logo} alt="logo" className="w-32 h-auto mx-auto" />
      </div>
      <div className="container">
        <div className="flex flex-wrap">
          <div className="flex-shrink-0 flex justify-center gap-x-6 w-full max-w-full mx-auto mb-6 text-center lg:flex-0 lg:w-8/12">
            <Link
              to="https://watti.com.co"
              className="mb-2 text-slate-400 dark:text-gray-400 sm:mb-0"
            >
              Inicio
            </Link>
            <Link
              to="https://watti.com.co/empresa"
              className="mb-2 text-slate-400 dark:text-gray-400 sm:mb-0"
            >
              Empresa
            </Link>
            <Link
              to="https://watti.com.co/servicio"
              className="mb-2 text-slate-400 dark:text-gray-400 sm:mb-0"
            >
              Servicio
            </Link>
            <Link
              to="https://watti.com.co/blog"
              className="mb-2 text-slate-400 dark:text-gray-400 sm:mb-0"
            >
              Blog
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-center mb-6">
          <Link
            to="https://www.instagram.com/watti.energia?igsh=dTRkYjRkcHQ5NjBi"
            className="text-slate-400 dark:text-gray-400 mx-2"
          >
            <IconBrandInstagram size={24} />
          </Link>
          <Link
            to="https://www.facebook.com/share/pR5AEnC6ptHUDNrH/?mibextid=qi2Omg"
            className="text-slate-400 dark:text-gray-400 mx-2"
          >
            <IconBrandFacebook size={24} />
          </Link>
          <Link
            to="https://x.com/wattienergia?s=11"
            className="text-slate-400 dark:text-gray-400 mx-2"
          >
            <IconBrandX size={24} />
          </Link>
          <Link
            to="https://www.linkedin.com/company/watti-energy/"
            className="text-slate-400 dark:text-gray-400 mx-2"
          >
            <IconBrandLinkedin size={24} />
          </Link>
          <Link
            to="https://www.youtube.com/@Watti-energy"
            className="text-slate-400 dark:text-gray-400 mx-2"
          >
            <IconBrandYoutube size={24} />
          </Link>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-8/12 max-w-full px-3 mx-auto mt-1 text-center flex-0">
            <p className="mb-0 text-slate-400 dark:text-gray-400">
              Copyright © {new Date().getFullYear()} Watti.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
