import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  LOGOUT,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL
} from "./types.js";

import axios from 'axios';
import Swal from "sweetalert2";

axios.interceptors.response.use(
response => response,
error => {
  return Promise.reject(error);
}
);

export const login = (email, password) => async dispatch => {
dispatch({
  type: SET_AUTH_LOADING
});

const config = {
  headers: {
    "Content-Type": "application/json"
  }
};

const body = JSON.stringify({
  email,
  password,
  origen: "web"
});

try {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/login`,
    body,
    config
  );

  if (res.data.statusCode === 412) {
    localStorage.setItem('userID',res.data.data);
    Swal.fire("Correo no verificado", "Por favor, verifica tu correo electrónico", "warning").then(() => {
      window.location.href = "/token/verify";
    });
    dispatch({
      type: REMOVE_AUTH_LOADING
    });
    return;
  }

  dispatch({
    type: LOGIN_SUCCESS,
    payload: res.data
  });

  dispatch({
    type: REMOVE_AUTH_LOADING
  });

} catch (err) {
  dispatch({
    type: LOGIN_FAIL
  });
  
  dispatch({
    type: REMOVE_AUTH_LOADING
  });

  if (err && err.response && err.response.status === 401) {
      Swal.fire("Error E-mail o Contraseña Invalido", "", "error");
  }
}
};

export const refresh = () => async dispatch => {
const token = sessionStorage.getItem('token');

if (!token) {
  dispatch({ type: REFRESH_TOKEN_FAIL });
  return;
}

dispatch({
  type: SET_AUTH_LOADING
});

const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  }
};

try {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/auth/check`,
    config
  );

  dispatch({
    type: REFRESH_TOKEN_SUCCESS,
    payload: res.data
  });

  dispatch({
    type: REMOVE_AUTH_LOADING
  });

} catch (err) {
  dispatch({
    type: REFRESH_TOKEN_FAIL
  });

  dispatch({
    type: REMOVE_AUTH_LOADING
  });
}
};

export const logout = () => dispatch => {
dispatch({
    type: LOGOUT
});
};

export const load_user = () => async dispatch => {
if (sessionStorage.getItem('token')) {
    const config = {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'Accept': 'application/json'
        }
    };
    const id = sessionStorage.getItem('id');
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user/${id}`, config);

        if (res.status === 200) {
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
} else {
    dispatch({
        type: USER_LOADED_FAIL
    });
}
};
